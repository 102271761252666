// COMMON FONTS --------------------------------------------------------------------

.section_title {
    font-size: 16px;
    line-height: 32px;
    text-transform: uppercase;
    font-weight: 300;
    color: #11171e;
}
.section_heading {
        color: #11171e;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 38px;
    // font-weight: 500;
    // line-height: 1.27;

    letter-spacing: .3em;
    text-transform: uppercase;
    font-weight: 300;
    color: #11171e;

    @media ($max1024) {
        font-size: 30px;
        line-height: 42px;
    }
    @media ($max640) {
      font-size: 26px;
      line-height: normal;
    }
    @media (max-width:375px) {
      font-size: 24px;
    }
}

.section_sub_heading {
    font-size: 14px;
    color: rgb(17, 23, 30);
    line-height: 18px;
    font-weight: 600;
    @media ($max992) {
      font-size: 15px;
      font-weight: 500;
    }
}
.sectionTitle{
  letter-spacing: .3em;
  text-transform: uppercase;
  font-weight: 300;
  color: #11171e;
  font-size: 42px;
  line-height:56px;
  span{
    letter-spacing: .3em;
    text-transform: uppercase;
    font-weight: 300;
    color: #11171e;
    font-size: 16px;
    line-height: 26px;
  }
  @media ($max992) {
    font-size: 28px;
    line-height: 32px;
  }
}
.contentBox{
  .sectionTitle{
    font-size: 38px;
    @media ($max992) {
      font-size: 32px;
      line-height: normal;
    }
  }
}
.section_content {
    font-size: 12px;
    line-height: 18px;
    color: rgb(51, 51, 51);
    font-weight: 400;

    @media ($max640) {
      font-size: 16px;
      line-height: 26px;
    }
}
.prefix {
  color: #304c67;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 26px;
}

// COMMON ANIMATIONS ---------------------------------------------------------------



.visit_number_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .first_num {
      font: normal normal 700 170px/170px $font-family_2;
      color: rgb(212, 214, 216);
    }

    @media ($max992) {
        scale: 0.7;  

        @media ($max640) {
            display: none;
        }
    }
    
    .scroll_num_cont {
      // position: absolute;
      overflow: hidden;
      height: 170px;
      right: 0;
      z-index: 4;
      bottom: -200px;
    
      .scroll_num_wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        width: fit-content;
    
        .scroll_num_in {
          font: normal normal 700 170px/170px $font-family_2;
          color: rgb(212, 214, 216);
          position: relative;
        }
    
        .scroll_num_fi {
          font: normal normal 700 170px/170px $font-family_2;
          color: rgb(212, 214, 216);
          position: relative;
        }
      }
    }
    
    .scroll_text_cont {
      position: absolute;
      z-index: 6;
    
      .scroll_text_wrapper {
        text-align: center;
        display: flex;
        flex-direction: column;
        height: 30px;
        overflow: hidden;
    
        .scroll_text_in {
          font: normal normal 500 12px/30px $font-family_1;
          color: rgb(153, 153, 153);
          position: relative;
          text-transform: uppercase;
        }
        .scroll_text_fi {
          font: normal normal 500 12px/30px $font-family_1;
          color: rgb(153, 153, 153);
          position: relative;
          text-transform: uppercase;
        }
      }
    }
  
  }
  
  
  .scroll_down_arr_wrapper {
    margin-top: 50px;
    height: 22px;
    overflow-y: hidden;
  
    @media ($max992) {
        margin-top: 20px;
    }
      .scroll_down_txt {
        font: normal normal 400 14px/22px $font-family_1;
        color: rgb(48, 76, 103);
        display: inline-block;
        position: relative;
  
        span {
          display: inline-block;
  
          .down_arrow {
            width: 20px;
            animation: arrow-down 1.5s infinite;
          }
  
        }
      }
    }
    
    // Animation keyframes
    @keyframes arrow-down {
      0% {
        transform: translateY(-22px);
      }
      50% {
        transform: translateY(00px);
      }
      100% {
        transform: translateY(22px);
      }
    }
  


// TABS PANEL COMMON SCSS ----------------------------------------------------------

$number-of-tabs: 4;
$number-of-tabs-gall: 2;
$separator-height: 0.188rem; // 3px

@mixin user-select() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

#location input[type="checkbox"],
#location input[type="radio"] {
  @include user-select();
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  clip: rect(0, 0, 0, 0);
  visibility: hidden;
  opacity: 0;
  transform: matrix3d(0, 0, 0.00, 0, 0.00, 0, 0.00, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  z-index: -1;

  @for $i from 1 through $number-of-tabs {
    &[id="test#{$i}"] {
      &:checked {
        & ~ .tabs {
          .tab {
            label[for="test#{$i}"] {
              opacity: 1;
              cursor: default;
              transition: all 0.4s ease-in-out;
            }
          }
        }
        & ~ .panels {
          #panel#{$i} {
            opacity: 1;
            display: block;
            animation: fade-in 1s ease-in-out; // Slowed down the animation to 1.5s
          }
        }
      }

      &:not(:checked) {
        & ~ .panels {
          #panel#{$i} {
            opacity: 0;
            display: none;
            animation: fade-out 1s ease-in-out; // Fade out with same duration
          }
        }
      }
    }
  }
}


#location{
  .tabs-panel {
    .tabs {
      display: flex;
      transition: all 0.4s ease-in-out;
  
      @media ($max640) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
  
      .tab {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        flex-basis: auto;
        flex-grow: 1;
  
        position: relative;
        width: 60%;
        margin-top: 2.5rem; // 40px
        margin-right: 0; // 50px
        text-align: center;
        color: #707a89;
        background-color: transparent;
        cursor: pointer;
        transition: backgroundcolor 1.5s ease-in-out;
  
        @media ($max1024) {
          width: unset;
        }
  
        label {
          @include user-select();
          display: block;
          height: 100%;
          padding: 0 0.75rem;
          border: none;
          cursor: pointer;
          color: rgb(34, 34, 34);
          opacity: 0.6;
  
          &:hover {
            color: rgb(34, 34, 34);
            opacity: 0.8;
          }
        }
      }
    }
  
    .separator {
      width: 100%;
      padding-top: 40px;
      border-bottom: 1px solid rgb(169, 171, 174);
    }
  
    .panels {
      border: none;
  
      .tab-panel {
        overflow: hidden;
        opacity: 0;
        display: none;
        transition: opacity 1.5s ease-in-out;
      //   position: absolute; 
        
        .content {
          font: normal normal 500 16px/26px $font-family_1;
          color: #2B2B2B;
        }
      }
    }
  }
}
//gallery
#gallery input[type="checkbox"],
#gallery input[type="radio"] {
  @include user-select();
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  clip: rect(0, 0, 0, 0);
  visibility: hidden;
  opacity: 0;
  transform: matrix3d(0, 0, 0.00, 0, 0.00, 0, 0.00, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  z-index: -1;

  @for $i from 1 through $number-of-tabs-gall {
    &[id="galltest#{$i}"] {
      &:checked {
        & ~ .galltabs {
          .galltab {
            label[for="galltest#{$i}"] {
              opacity: 1;
              cursor: default;
              transition: all 0.4s ease-in-out;
            }
          }
        }
        & ~ .panelsGall {
          #gallpanel#{$i} {
            opacity: 1;
            display: block;
            animation: fade-in 1s ease-in-out; // Slowed down the animation to 1.5s
          }
        }
      }

      &:not(:checked) {
        & ~ .panelsGall {
          #gallpanel#{$i} {
            opacity: 0;
            display: none;
            animation: fade-out 1s ease-in-out; // Fade out with same duration
          }
        }
      }
    }
  }
}
#gallery{
  .tabs-panel-gall {
    .galltabs {
      display: flex;
      transition: all 0.4s ease-in-out;
  
      @media ($max640) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
  
      .galltab {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        flex-basis: auto;
        flex-grow: 1;
  
        position: relative;
        width: 60%;
        margin-top: 2.5rem; // 40px
        margin-right: 0; // 50px
        text-align: center;
        color: #707a89;
        background-color: transparent;
        cursor: pointer;
        transition: backgroundcolor 1.5s ease-in-out;
  
        @media ($max1024) {
          width: unset;
        }
  
        label {
          @include user-select();
          display: block;
          height: 100%;
          padding: 0 0.75rem;
          border: none;
          cursor: pointer;
          color: rgb(34, 34, 34);
          opacity: 0.6;
  
          &:hover {
            color: rgb(34, 34, 34);
            opacity: 0.8;
          }
        }
      }
    }
  
    .separator {
      width: 100%;
      padding-top: 40px;
      border-bottom: 1px solid rgb(169, 171, 174);
    }
  
    .panelsGall {
      border: none;
  
      .tab-panel-gall {
        opacity: 0;
        display: none;
        transition: opacity 1.5s ease-in-out; // Slowing down the transition for panelsGall
      //   position: absolute; 
        
        .content {
          font: normal normal 500 16px/26px $font-family_1;
          color: #2B2B2B;
        }
      }
    }
  }
}
.themeBtn{
  color: #e5e8eb;
  background-color: #232323;
  padding: 10px 18px;
  font-size: 18px;
}
.themeBtn, #submitBtn, .theme-btn{
  cursor: pointer;
}
.scroll-trigger{
  cursor: pointer;
}

// form overlay 
/* Overlay Background */
.overlay {
	display: none; /* Hidden by default */
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7); /* Transparent black */
	z-index: 1000;
}

/* Popup Content */
.popup-content {
	position: relative;
	margin: auto;
	top: 50%;
	transform: translateY(-50%);
	background: white;
	border-radius: 10px;
	max-width: 600px;
	width: 90%;
	padding: 20px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Close Icon */
.close-icon {
	position: absolute;
	top: 10px;
	right: 15px;
	font-size: 24px;
	cursor: pointer;
}
.dispInBlock{
  display: inline-block;
}

.geeta_bulders {
  width: 200px;
}
$color_1: #fff;
$color_2: #000;
$color_3: #6b6b6b;
$background-color_1: #6b6b6b;
$background-color_2: #fff;
$background-color_3: rgba(255, 254, 254, 0.97);

/* Large Devices, Wide Screens */
/* Large Devices, Wide Screens */
/* Medium Devices, Desktops */
/* Small Devices, Tablets */
/* Extra Small Devices, Phones */
/* Extra Small Devices, Phones */
/* Extra Small Devices, Phones */
@media only screen and (max-width : 1024px) {
	.col-md-1 {
		width: 8.333333333333333%;
	}
	.col-md-2 {
		width: 16.66666666666667%;
	}
	.col-md-3 {
		width: 25%;
	}
	.col-md-4 {
		width: 33.33333333333333%;
	}
	.col-md-5 {
		width: 41.66666666666667%;
	}
	.col-md-6 {
		width: 50%;
	}
	.col-md-7 {
		width: 58.33333333333333%;
	}
	.col-md-8 {
		width: 66.66666666666667%;
	}
	.col-md-9 {
		width: 75%;
	}
	.col-md-10 {
		width: 83.33333333333333%;
	}
	.col-md-11 {
		width: 91.66666666666667%;
	}
	.col-md-12 {
		width: 100%;
	}
	.col-md-12-5 {
		width: 20%;
	}
	.hidden-md {
		display: none !important;
	}
	.visible-md {
		display: block !important;
	}
}
@media only screen and (max-width : 992px) {
	.col-sm-1 {
		width: 8.333333333333333%;
	}
	.col-sm-2 {
		width: 16.66666666666667%;
	}
	.col-sm-3 {
		width: 25%;
	}
	.col-sm-4 {
		width: 33.33333333333333%;
	}
	.col-sm-5 {
		width: 41.66666666666667%;
	}
	.col-sm-6 {
		width: 50%;
	}
	.col-sm-7 {
		width: 58.33333333333333%;
	}
	.col-sm-8 {
		width: 66.66666666666667%;
	}
	.col-sm-9 {
		width: 75%;
	}
	.col-sm-10 {
		width: 83.33333333333333%;
	}
	.col-sm-11 {
		width: 91.66666666666667%;
	}
	.col-sm-12 {
		width: 100%;
	}
	.col-sm-12-5 {
		width: 20%;
	}
	.set-transform {
		transform:unset;
		transition: unset;
	}
	header {
		.form-container.show {
			top: 0 !important;
			bottom: 0 !important;
			display: block !important;
		}
		.nav-links {
			display: none;
			position: fixed;
			top: 60px;
			left: 0;
			background-color: $background-color_2;
			width: 100%;
			height: 100%;
			z-index: 999;
			transition: unset;
			a {
				display: block !important;
				text-align: center;
				line-height: 40px !important;
				color: $color_1;
				margin-top: 0px;
				margin-right: 0px;
			}
		}
		.lower {
			.nav-links {
				a {
					&:last-child {
						margin-right: 0px;
					}
					margin-right: 0px;
					color: $color_2;
				}
			}
		}
		.upper {
			.enq-btn-wrapper {
				margin-right: 50px;
				margin-top: 15px;
			}
			.ctc-wrapper {
				margin-right: 5px;
			}
			.number-wrapper {
				margin-right: 15px;
				margin-top: 25px;
			}
		}
	}
	.menu-icon-mobile {
		display: block;
		width: 50px;
		padding: 14px 8px 4px 8px;
		background-color: transparent;
		border-radius: 0px;
		margin: 0;
		position: absolute;
		top: 10px;
		right: 0;
		height: 40px;
		>span {
			display: block;
			height: 2px;
			width: 60%;
			margin-bottom: 4px;
			background-color: #000;
		}
	}
	.menu-icon-mobile.active {
		>span {
			&:nth-child(1) {
				transform: rotate(45deg);
			}
			&:nth-child(2) {
				transform: rotate(135deg);
				position: relative;
				top: -5px;
			}
			&:nth-child(3) {
				display: none;
			}
		}
	}
	.hidden-sm {
		display: none !important;
	}
	.visible-sm {
		display: block !important;
	}
	section.padding {
		padding: 35px 0px;
	}
	#hid-on-mob {
		display: none !important;
	}
	.home-banner {
		background-image: url('https://dummyimage.com/768X1024/6b6b6b/565659&text=ipad-banner');
	}
}
@media only screen and (max-width : 640px) {
	body {
		-webkit-text-size-adjust: none;
	}
	.col-xs-1 {
		width: 8.333333333333333%;
	}
	.col-xs-2 {
		width: 16.66666666666667%;
	}
	.col-xs-3 {
		width: 25%;
	}
	.col-xs-4 {
		width: 33.33333333333333%;
	}
	.col-xs-5 {
		width: 41.66666666666667%;
	}
	.col-xs-6 {
		width: 50%;
	}
	.col-xs-7 {
		width: 58.33333333333333%;
	}
	.col-xs-8 {
		width: 66.66666666666667%;
	}
	.col-xs-9 {
		width: 75%;
	}
	.col-xs-10 {
		width: 83.33333333333333%;
	}
	.col-xs-11 {
		width: 91.66666666666667%;
	}
	.col-xs-12 {
		width: 100%;
	}
	.col-xs-12-5 {
		width: 20%;
	}
	.hidden-xs {
		display: none !important;
	}
	.visible-xs {
		display: block !important;
	}
	.menu-icon-mobile {
		height: 60px;
		padding: 25px 8px 4px 8px;
		top: -8px;
	}
	header {
		padding: 10px 0;
		.nav-links {
			padding: 10px 0;
			top: 55px;
		}
		.brand-logo {
			img {
				width: 85px;
			}
		}
		.form-container {
			position: fixed !important;
			left: 0 !important;
			right: 0 !important;
			top: 100% !important;
			bottom: -100% !important;
			width: 100% !important;
			padding: 30px 0 !important;
			-ms-transform: translate(0, 0);
			-webkit-transform: translate(0, 0);
			transform: translate(0, 0);
			z-index: 300;
			background-color: $background-color_3 !important;
			.frmclose {
				display: inline-block;
				position: absolute;
				top: 10px;
				right: 10px;
				color: $color_2;
				font-size: 20px;
			}
		}
		.form-container.show {
			top: 0 !important;
			bottom: 0 !important;
			display: block !important;
		}
	}
	footer {
		padding-bottom: 30px;
		.lower {
			font-size: 10px;
			div {
				text-align: center !important;
			}
		}
	}
	.mob-footer {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		text-align: center;
		background-color: $background-color_2;
		font-size: 14px;
		font-weight: 600;
		z-index: 999999;
		a {
			display: block;
			line-height: 30px;
		}
		a.mob-enq-btn {
			background-color: $background-color_1;
			color: $color_1;
			text-transform: uppercase;
			font-weight: 600;
			font-size: 14px;
		}
		a.mob-call-btn {
			background-color: $background-color_2;
			color: $color_3;
			text-transform: uppercase;
			font-weight: 600;
			font-size: 14px;
		}
	}
	.home-banner {
		background-image: url('https://dummyimage.com/320X480/6b6b6b/565659&text=mobile-banner');
	}
}

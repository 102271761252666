$pc: #000057;
$sc: #C5A260;
$white: #fff;
$black: #000;
$color_1: #4d4c4f;
$color_2: #000;
$color_3: #fff;
$color_4: #ee6e25;
$color_5: red;
$color_6: #6b6b6b;
$font-family_1: 'Euclid Circular B';
$font-family_2: 'MADE Mirage';
$background-color_1: #fff;
$background-color_2: #f8f8fa;
$background-color_3: #6b6b6b;
$background-color_4: #efece7;
$background-color_5: #672F90;
$background-color_6: #2b2b2b;
$background-color_7: #121212;
$min_wd : min-width;
$max_wd : max-width;
$imgpath:'../../assets/images';
$max1920 : 'max-width:1920px';
$max1600 : 'max-width:1600px';
$max1440 : 'max-width:1440px';
$max1366 : 'max-width:1366px';
$max1280 : 'max-width:1280px';
$max1024 : 'max-width:1024px';
$max992 : 'max-width:992px';
$max640 : 'max-width:640px';
$max360 : 'max-width:360px';
$min1600 : 'min-width:1600px';
$min1440 : 'min-width:1440px';
$min1366 : 'min-width:1366px';
$min1280 : 'min-width:1280px';
$min1024 : 'min-width:1024px';
$min992 : 'min-width:992px';
$min640 : 'min-width:640px';



.grid_container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    width: 100%;
}

.col_1 {
  grid-column: auto/span 1;
}

.col_2 {
  grid-column: auto/span 2;
}

.col_3 {
  grid-column: auto/span 3;
}

.col_4 {
  grid-column: auto/span 4;
}

.col_5 {
  grid-column: auto/span 5;
}

.col_6 {
  grid-column: auto/span 6;
}

.col_7 {
  grid-column: auto/span 7;
}

.col_8 {
  grid-column: auto/span 8;
}

.col_9 {
  grid-column: auto/span 9;
}

.col_10 {
  grid-column: auto/span 10;
}

.col_11 {
  grid-column: auto/span 11;
}

.col_12 {
  grid-column: auto/span 12;
}


.text_uppercase {
    text-transform: uppercase;
}

@media ($max640) {
  .col_xs_1 {
    grid-column: auto/span 1;
  }
  
  .col_xs_2 {
    grid-column: auto/span 2;
  }
  
  .col_xs_3 {
    grid-column: auto/span 3;
  }
  
  .col_xs_4 {
    grid-column: auto/span 4;
  }
  
  .col_xs_5 {
    grid-column: auto/span 5;
  }
  
  .col_xs_6 {
    grid-column: auto/span 6;
  }
  
  .col_xs_7 {
    grid-column: auto/span 7;
  }
  
  .col_xs_8 {
    grid-column: auto/span 8;
  }
  
  .col_xs_9 {
    grid-column: auto/span 9;
  }
  
  .col_xs_10 {
    grid-column: auto/span 10;
  }
  
  .col_xs_11 {
    grid-column: auto/span 11;
  }
  
  .col_xs_12 {
    grid-column: auto/span 12;
  }
}
#image1,
#image2 {
  width: 100%;
  height: 100vh;
}

#first_visit {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: hsla(204, 8%, 87%, 1);

  .logo_wrapper {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 200px;
      mix-blend-mode: darken;
    }
  }
}

.medium-sub-heading {
  color: #11171e;
  letter-spacing: .02em;
  font-size: 1.04em;
  font-weight: 500;
  line-height: 1.4;
}

.medium-sub-heading.home-hero-bottom {
  max-width: 40ch;
}

#image2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .banner_slide {
    width: 100%;
    height: 100vh;

    @media ($max1024) {
      height: 100vh;
    }
  }
}

.medium-sub-heading {
  color: #11171e;
  letter-spacing: .02em;
  font-size: 1.04em;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 20px;
}

.medium-sub-heading.home-hero-bottom {
  max-width: 40ch;
}

.home_banner {
  background-color: #eef0f2;
  position: relative;
  z-index: 4;
  min-height: 100vh;

  .grid_container {

    @media ($max640) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .container {

    @media ($max640) {
      padding: unset;
    }
  }

  .banner_left_cont {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 90px;
    display: flex;

    @media ($max992) {
      padding-top: 80px;

      @media ($max640) {
        padding: 20px 20px;
      }
    }

    .banner_btm_line {
      width: 135%;
      height: 1px;
      background-color: #adafb1;
      margin: 50px -64px;

      @media ($max1024) {
        margin: 30px 0;
      }
    }
  }

  .banner_image_container {
    position: relative;
    z-index: 2;
    width: 503px;
    height: 640px;
    overflow: hidden;
    margin: 10px auto;

    @media ($max1024) {
      width: 350px;
      height: 470px;
      margin: 50px 0;

      @media ($max992) {
        width: 300px;
        height: 380px;

        @media ($max640) {
          width: 100%;
          height: 400px;
          // min-height: 100vh;
          margin-top: 0;
        }
      }
    }

    img {
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) scale(1);
      transform-origin: 50% 50%;
    }
  }

  .visit_number_wrapper {
    right: -150px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;

    @media ($max1024) {
      // right: -75px;
      right: 0px;
    }

    @media ($max992) {
      right: -60px;
    }

    .first_num {
      font: normal normal 700 170px/170px $font-family_2;
      color: rgb(212, 214, 216);
    }

    .scroll_num_cont {
      // position: absolute;
      overflow: hidden;
      height: 170px;
      right: 0;
      z-index: 4;
      bottom: -200px;

      .scroll_num_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        width: fit-content;

        .banner_scroll_num_in {
          font: normal normal 700 170px/170px $font-family_2;
          color: rgb(212, 214, 216);
          position: relative;
        }

        .banner_scroll_num_fi {
          font: normal normal 700 170px/170px $font-family_2;
          color: rgb(212, 214, 216);
          position: relative;
        }
      }
    }

    .scroll_text_cont {
      position: absolute;
      z-index: 6;

      .scroll_text_wrapper {
        text-align: center;
        display: flex;
        flex-direction: column;
        height: 30px;
        overflow: hidden;

        .banner_scroll_text_in {
          font: normal normal 500 12px/30px $font-family_1;
          color: rgb(153, 153, 153);
          position: relative;
        }

        .banner_scroll_text_fi {
          font: normal normal 500 12px/30px $font-family_1;
          color: rgb(153, 153, 153);
          position: relative;
        }
      }
    }

  }

  .future_cont_wrapper {
    background-color: rgb(35, 35, 35);
    padding: 140px 0;
    margin-top: -70px;
    z-index: -1;
    width: 110%;
    position: relative;
    left: -4%;

    @media ($max1024) {
      margin-top: -50px;

      @media ($max992) {
        margin-top: -30px;
        padding: 60px 20px 90px;

      }
    }

    @media ($max640) {
      margin-top: 0;
      padding: 80px 30px;
      display: flex;
      flex-direction: column;
      width: 90%;
      margin: 40px auto;
      left: 0;
    }

    .section_heading {
      color: rgb(230, 231, 232);
      // letter-spacing: 11px;
      width: 530px;
      margin: auto;
      padding-left: 40px;
      letter-spacing: .3em;
      text-transform: uppercase;
      font-weight: 300;
      font-size: 38px;

      @media ($max1024) {
        width: 370px;
      }

      @media ($max640) {
        width: 100%;
        font-size: 27px;
        // letter-spacing: 8px;
        padding-right: 0px;
        padding-left: 0;
      }

    }

    .section_content {
      color: rgb(230, 231, 232);
      // width: 350px;
      // padding-right: 50px;
      max-width: 575px;
      margin: auto;

      @media ($max1024) {
        // width: 300px;
        margin-left: auto;

      }

      @media ($max640) {
        width: 100%;
        margin: 20px 0 0 0;
        padding-right: 0;
      }

    }
  }

  .overview_container {

    @media ($max640) {
      display: flex;
      flex-direction: column;
    }

    .overview_text_wrapper {
      padding-top: 103px;
      padding-left: 100px;

      @media ($max1024) {
        padding-top: 30px;

        @media ($max640) {
          padding: 30px 40px;
        }
      }

      .section_heading {
        color: rgb(17, 23, 30);
        // font-weight: 500;
      }

      .section_content {
        color: rgb(35, 35, 35);
        padding-top: 25px;
      }

      .ovr_specs_wrapper {
        margin-top: 50px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        @media ($max640) {
          grid-template-columns: 1fr 1fr;
        }

        .ovr_specs_cont {
          padding: 30px 15px 30px 20px;
          border: 1px solid rgb(212, 214, 216);
          width: 173px;

          @media ($max1024) {
            padding: 20px 15px 20px 20px;

            @media ($max992) {
              padding: 25px;
              width: 100%;

              @media ($max640) {
                padding: 25px;
                width: 100%;
              }
            }

          }

          .tower_height {
            width: 35px;
            height: 35px;
          }

          .spec_heading {
            font: normal normal 500 9px/12px $font-family_1;
            color: rgb(109, 110, 113);
            text-transform: uppercase;

            @media ($max640) {
              font-size: 10px;
              line-height: 20px;
              padding-top: 5px;
            }
          }

          .spec_main {
            font: normal normal 500 22px/28px $font-family_1;
            color: rgb(48, 76, 103);
            letter-spacing: 1px;

            @media ($max992) {
              font-size: 16px;
              line-height: 22px;

              @media ($max640) {
                font-size: 22px;
                line-height: 28px;
                padding-top: 5px;
              }
            }
          }
        }
      }

    }

    .overview_image_container {
      position: relative;
      z-index: 2;
      width: 445px;
      height: 586px;
      overflow: hidden;
      margin: 75px auto 50px;

      @media ($max1024) {
        width: 360px;
        height: 450px;

        @media ($max992) {
          width: 320px;
          height: 400px;

          @media ($max640) {
            margin: 30px auto;
            width: 80%;
          }
        }
      }

      img {
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%) scale(1.4);
        transform-origin: 50% 50%;

        @media ($max640) {
          width: 100%;
          height: auto;
        }
      }
    }

  }

  .ovr_conclusion_text_wrap {

    .ovr_conclusion_text {
      font: normal normal 500 14px/22px $font-family_1;
      color: rgb(17, 23, 30);
      width: 470px;
      margin: 20px 0 120px 35px;

      @media ($max1024) {
        width: 370px;
        margin: 40px 0 120px 20px;

        @media ($max992) {
          width: 340px;

          @media ($max640) {
            width: 100%;
            font-size: 16px;
            margin: 40px 0;
            padding: 0 20px;
          }

        }
      }

    }
  }
}

.location {
  background-color: rgb(229, 232, 235);
  padding-bottom: 0 !important;

  .location_cont_1 {

    @media ($max640) {
      display: flex;
      flex-direction: column;
      padding: 30px;
    }

    .section_content {
      color: rgb(35, 35, 35);
      margin-top: 40px;
      width: 375px;

      @media ($max640) {
        width: 100%;
      }
    }

    .loc_img_wrapper {
      margin-top: -250px;
      position: relative;
      z-index: 6;

      @media ($max640) {
        margin: 40px 0 0;
      }

      .loc_image_container {
        position: relative;
        z-index: 2;
        width: 443px;
        height: 591px;
        overflow: hidden;
        margin: -50px auto 50px;

        @media ($max1024) {
          width: 350px;
          height: 480px;
          margin: -60px 0 50px;

          @media ($max640) {
            margin: 40px auto 0;
            width: 100%;
          }
        }

        img {
          height: 100%;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%) scale(1);
          transform-origin: 50% 50%;

          @media ($max640) {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .visit_number_wrapper {
      position: absolute;
      bottom: 0;
      right: -130px;

      @media ($max1024) {
        right: 0;
      }

      @media ($max992) {
        right: -60px;
      }
    }

  }

  .loc_points_wrapper {
    margin-top: 120px;

    @media ($max992) {
      margin-top: 80px;

      @media ($max640) {
        margin-top: 20px;
      }
    }

    .section_heading {
      color: rgb(17, 23, 30);
      text-align: center;
      letter-spacing: 1px;
      // font-weight: 500;
    }

    .section_content {
      color: rgb(51, 51, 51);
      text-align: center;
      width: 370px;
      margin: 25px auto 80px;

      @media ($max992) {
        margin: 25px auto 40px;
      }

      @media ($max640) {
        width: unset;
      }
    }

    .loc_points_cont {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 60px 20px;
      width: 750px;
      margin: auto;

      @media ($max992) {
        scale: 0.8;

        @media ($max640) {
          scale: 1;
          grid-template-columns: 1fr 1fr;
          width: 100%;
          gap: 40px;
          margin-top: 20px;
        }
      }

      .loc_points {
        text-align: center;

        .loc_image {
          width: 54px;
          height: auto;

          @media ($max640) {
            width: 64px;
          }
        }

        .loc_desc {
          font: normal normal 500 14px/18px $font-family_1;
          color: rgb(51, 51, 51);
          margin-top: 5px;

          @media ($max640) {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }

  }

  .loc_specs_wrapper {
    margin-top: 80px;
    width: 100%;
    position: relative;
    z-index: 6;

    .tabs-panel {

      .tab_label {
        font: normal normal 400 14px/18px $font-family_1;
        color: rgb(34, 34, 34);
        text-transform: uppercase;
      }

      .panels {
        padding: 60px 0;
        min-height: 300px;

        .tabs_panel_wrapper {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          justify-content: center;
          align-items: center;
          gap: 30px 20px;

          @media ($max640) {
            padding-bottom: 20px;
          }

          .cont_box {
            background-color: rgb(234, 236, 239);
            box-shadow: rgba(0, 0, 0, 0.15) 5px 0px 36px -9px;
            width: 200px;
            height: 233px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin: 0 auto;

            @media ($max1024) {
              width: 160px;
              height: 200px;

              @media ($max992) {
                height: 160px;

                @media ($max640) {
                  width: 233px;
                  height: 273px;
                }
              }
            }

            .cont_num {
              font: normal normal 500 22px/34px $font-family_1;
              color: rgb(48, 76, 103);
            }

            .cont_text {
              font: normal normal 400 10px/16px $font-family_1;
              color: rgb(51, 51, 51);
              letter-spacing: 0.1px;
            }
          }
        }
      }
    }
  }

  .loc_map_wrapper_main {
    position: relative;
    z-index: 2;
    margin-top: 0px;

    @media ($max1024) {
      margin-top: -20px;
      margin-bottom: -30px;

      @media ($max640) {
        margin-top: 40px;
      }
    }

    .loc_map_overlay {
      position: absolute;
      background-color: #e5e8eb;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 3;
      display: flex;
      justify-content: center;
      align-items: start;

      .map_overlay_cont {
        padding-top: 90px;

        .section_content {
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          color: rgb(51, 51, 51);
          font-weight: 500;
          width: 645px;

          @media ($max640) {
            width: 90%;
            margin: auto;
          }
        }
      }
    }

    .map_image_wrapper {
      max-width: 1920px;
      margin: auto;
      width: 100%;
      height: auto;
      aspect-ratio: 16/8;
      // overflow: hidden;

      @media ($max1024) {
        height: 100%;
        aspect-ratio: unset;
      }

      img {
        width: 100%;
        transform: translateY(0px);

        @media ($max1024) {
          transform: unset;
          background-color: #fff;
        }
      }
    }
  }
}

.gallery {
  background-color: #eef0f2;
  position: relative;

  &.padding {
    padding-bottom: 0px;

    @media ($max640) {
      padding-bottom: 50px;
    }
  }

  .mobCont {
    @media ($max640) {
      padding: 0 15px;
    }
  }

  .title {
    letter-spacing: 12px;
    margin: 20px auto 80px;
    text-align: left;
    width: fit-content;
  }

  .tabs_panel_wrapper {
    .gallBox {
      // margin-top: 30px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 0 20px;
      transition: transform 0.5s ease-in-out;
      padding: 0 50px;
      max-width: 1640px;
      margin: 30px auto;

      @media ($max640) {
        grid-template-columns: auto;
        grid-gap: 10px 0;
      }

      img {
        aspect-ratio: 3/2;
      }

      a {
        display: block;
        transition: transform 0.5s ease-in-out;
      }

      &:hover {
        /* This is the default hover behavior. */
        /* The jQuery will override this based on mouse position dynamically. */
        transform: translateX(0);
      }
    }

  }

  .dbroWrap {
    padding-top: 30px;

    form {
      padding-top: 30px;

      .frow {
        justify-content: center;
      }

      input[type="email"] {
        height: 100%;
        padding: 2px 10px !important;
        // &::placeholder{
        //   padding: 2px 10px;
        // }
      }
    }

    .thankyouWrap {
      padding-top: 30px;

      .theme-btn {
        display: inline-block;
        margin-top: 20px;
      }
    }
  }
}
#dbroForm{
  .form-group{
    padding-bottom: 5px;
  }
}

.walkthrough {
  @media ($max640) {
    margin-top: 50px;
  }

  .floatCounter {
    position: absolute;
    top: 30%;
    right: 10px;
    z-index: 2;

    @media ($max992) {
      right: 0;
    }
  }


  .banner-video {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    margin-top: 40px;
    position: relative;
    z-index: 5;
  }
}

.amenities {
  .floatCounter {
    position: absolute;
    top: 70px;
    right: 30px;

    @media ($max992) {
      right: 0;
    }
  }

  .container {
    .grid_container {
      align-items: center;

      &.cont2 {
        margin-top: 50px;

        .section_sub_heading {
          font: normal normal 500 14px/22px Euclid Circular B;
        }

        @media ($max1024) {
          margin-top: 20px;

          .iconWrapper {
            margin-top: 30px;
          }
        }

        .imgBox {
          @media ($max640) {
            margin-top: 20px;
          }
        }
      }

      .contentBox {
        display: grid;
        height: 100%;
        align-items: center;
        justify-content: space-between;

        &.paddingRight {
          padding-right: 30px;

          @media ($max992) {
            padding-right: 40px;
          }
        }

        &.paddingLeft {
          padding-left: 70px;

          @media ($max992) {
            padding-left: 40px;
          }

          @media ($max640) {
            padding-left: 0;
          }
        }

        .iconWrapper {
          // margin-top: 80px;
          display: flex;
          justify-content: space-between;
          gap: 0 55px;

          @media ($max992) {
            gap: 0 20px;
            align-items: center;
          }

          @media ($max640) {
            margin-top: 20px;
            flex-direction: column;
            gap: 20px 0;
          }

          .iconCont {
            .svgWrap {
              svg {
                height: 65px;
                width: auto;
                aspect-ratio: 5/4;

                // @media ($max992) {
                //   width: 38px;
                // }

                // @media ($max640) {
                //   width: 65px;
                // }
              }
            }

            .infoTitle {
              letter-spacing: .02em;
              font-size: 16px;
              font-weight: 500;
              line-height: 1.57;
              padding: 10px 0;
              border-bottom: 1px solid #adafb1;

              @media ($max992) {
                font-size: 16px;
                font-weight: 500;
              }
            }

            .info {
              margin-top: 10px;
              letter-spacing: .02em;
              margin-bottom: 0;
              font-size: 14px;
              line-height: 1.57;

              @media ($max992) {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

.torchEffect {
  background-color: #fff;
  position: relative;
  overflow: hidden;

  .mouseEffectRow {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    z-index: 2; // Text should be above the background

    &.visible {
      background-color: #fff;
      justify-content: center;

      .mouseEffectText {
        color:  #fff;
        mix-blend-mode: exclusion;
      }

    }

    .mouseEffectText {
      color: #fff; // Initially invisible text
      font-size: 28px;
      font-weight: 500;
      line-height: 1.5;
      position: relative;
      z-index: 2;
      transition: color 0.6s ease-in-out; // Smooth transition for visibility
    }
  }
}

.circleMask {
  position: absolute;
  background-color: #232323; // Black circle
  border-radius: 50%;
  width: 300px;
  height: 300px;
  z-index: 1;
  pointer-events: none;
  display: none; // Hidden by default
}

//torch effect ends here

.lounges {
  background-color: #eef0f2;

  .container {
    .loungeBox {
      display: grid;
      grid-template-columns: 26% auto;
      grid-gap: 0 60px;

      @media ($max640) {
        grid-template-columns: auto;
        grid-gap: 20px 0;
      }

      &.cont2 {
        margin-top: 50px;

        .section_sub_heading {
          font: normal normal 500 14px/22px Euclid Circular B;
        }

        @media ($max1024) {
          margin-top: 20px;

          .iconWrapper {
            margin-top: 30px;
          }
        }

        .imgBox {
          @media ($max640) {
            margin-top: 20px;
          }
        }
      }

      .contentBox {
        display: grid;
        height: 100%;
        align-items: center;
        justify-content: space-between;

        &.paddingRight {
          padding-right: 30px;

          @media ($max992) {
            padding-right: 40px;
          }
        }

        &.paddingLeft {
          padding-left: 70px;

          @media ($max992) {
            padding-left: 40px;
          }

          @media ($max640) {
            padding-left: 0;
          }
        }

        .iconWrapper {
          // margin-top: 80px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 0 30px;

          @media ($max992) {
            gap: 0 20px;
            align-items: center;

            @media ($max640) {
              align-items: start;
            }
          }

          @media ($max640) {
            margin-top: 20px;
            flex-direction: column;
            gap: 20px 0;
          }

          .iconCont {
            .svgWrap {
              svg {
                height: 65px;
                width: auto;
                aspect-ratio: 5/4;

                // @media ($max992) {
                //   width: 38px;
                // }

                // @media ($max640) {
                //   width: 65px;
                // }
              }
            }

            .infoTitle {
              letter-spacing: .02em;
              font-size: 16px;
              font-weight: 500;
              line-height: 1.57;
              padding: 10px 0;
              border-bottom: 1px solid #adafb1;

              @media ($max992) {
                font-size: 16px;
                font-weight: 500;
              }
            }

            .info {
              margin-top: 10px;
              letter-spacing: .02em;
              margin-bottom: 0;
              font-size: 14px;
              line-height: 1.57;

              @media ($max992) {
                font-size: 14px;
              }
            }
          }
        }
      }

      .imgBox {
        position: relative;
        z-index: 2;
        width: 805px;
        height: 453px;
        overflow: hidden;
        margin: -50px auto 50px;

        @media ($max1024) {
          width: 620px;
          height: 480px;
          margin: 20px auto 50px;

          @media ($max992) {
            width: 100%;
            height: 380px;

            @media ($max640) {
              margin: 40px auto 0;
              width: 100%;
              height: auto;
            }
          }
        }

        img {
          height: 100%;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%) scale(1.4);
          transform-origin: 50% 50%;

          @media ($max640) {
            width: 100%;
            height: auto;
            position: unset;
            transform: unset;
            transform-origin: unset;
          }
        }
      }

    }
  }
}

.gHeights {
  background-color: #eef0f2;

  .gHeightsBox {
    display: grid;
    grid-template-columns: 55% auto;
    grid-gap: 0 80px;
    align-items: center;

    @media ($max992) {
      grid-gap: 0 40px;
    }

    @media ($max640) {
      grid-template-columns: auto;
      grid-gap: 20px 0;
    }

    .imgBox {
      img {
        @media ($max992) {
          aspect-ratio: 1/1;
        }
      }
    }

    .contentBox {
      .prefix {
        color: #304c67;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 26px;
      }

      .infoTitle {
        margin-top: 20px;
        font-weight: 300;
      }

      .info {
        margin-top: 34px;
        font-size: 12px;

        @media ($max992) {
          margin-top: 15px;
          font-size: 16px;
        }
      }
    }
  }
}

.bWider {
  &.padding {
    // padding-bottom: 0;
  }

  .container {
    &.bgBlack {
      background-color: #232323;
      padding-top: 100px;
    }

    .title {
      text-align: center;
      color: #fff;
      margin-bottom: 600px;

      @media ($max640) {
        margin-bottom: 300px;
      }
    }

    .longerBuild {
      margin-top: -500px;

      @media ($max1024) {
        margin-top: -560px;
      }

      @media ($max640) {
        margin-top: -250px;
      }

      img {
        width: 60%;

        @media ($max1024) {
          width: 70%;
        }

        @media ($max992) {
          width: 80%;
        }

        @media ($max640) {
          width: 90%;
        }
      }
    }
  }

  .bottomMain {
    .section_sub_heading {
      font-weight: 500;
    }
  }
}

.stories {
  background-color: #eef0f2;

  .container {
    position: relative;

    .visit_number_wrapper {
      position: absolute;
      top: 120px;
      right: -30px;

      @media ($max1024) {
        right: 0;
      }

      @media ($max992) {
        right: -60px;
      }
    }

    .storiesBox {
      display: grid;
      grid-template-columns: 40% auto;
      grid-gap: 0 120px;

      // align-items: center;
      @media ($max992) {
        grid-gap: 0 40px;
      }

      @media ($max640) {
        grid-template-columns: auto;
        grid-gap: 20px 0;
      }

      .imgBox {}

      .contentBox {
        .iconWrapper {
          margin-top: 80px;
          display: flex;
          justify-content: space-between;
          gap: 0 80px;

          @media ($max992) {
            margin-top: 20px;
            gap: 0 30px;
            align-items: center;

            @media ($max992) {
              flex-direction: column;
              gap: 20px;
            }
          }

          .iconCont {
            .svgWrap {
              svg {
                height: 65px;
                width: auto;
                aspect-ratio: 5/4;

                // @media ($max992) {
                //   width: 38px;
                // }

                // @media ($max640) {
                //   width: 65px;
                // }
            }

          }

  
          .infoTitle {
            letter-spacing: .02em;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.57;
            padding: 10px 0;
            border-bottom: 1px solid #adafb1;

            @media ($max992) {
              font-size: 16px;
              font-weight: 500;
            }
          }

          .info {
            margin-top: 10px;
            letter-spacing: .02em;
            margin-bottom: 0;
            font-size: 12px;
            line-height: 1.57;

            @media ($max992) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
}

.workspace {
  background-color: #333;

  .container {
    color: #fff;

    .workSpaceBox {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 0 60px;

      @media ($max640) {
        grid-template-columns: auto;
        grid-gap: 10px 0;
      }

      .contentBox {
        display: flex;
        flex-direction: column;
        gap: 60px 0;
        align-items: start;

        @media ($max640) {
          grid-gap: 20px 0;
        }

        .title {
          color: #fff;
        }

        .formWrapper {
          padding-right: 100px;
          width: 100%;

          @media ($max640) {
            padding-right: 0;
          }

          form {
            display: grid;
            grid-gap: 25px 0;

            .form-group label.error {
              bottom: -20px;
            }

            input {
              border: 1px solid #fff;
              background-color: transparent;
              padding: 10px;
              color: #fff;
              width: 100%;

              &::placeholder {
                color: #fff;
              }
            }

            textarea {
              border: 1px solid #fff;
              background-color: transparent;
              padding: 10px;
              color: #fff;
              width: 100%;

              &::placeholder {
                color: #fff;
              }
            }
          }
        }
      }

      .imgBox {
        position: relative;
        z-index: 2;
        width: 555px;
        height: 875px;
        overflow: hidden;
        margin: -150px auto 50px;

        @media ($max1024) {
          width: 450px;
          height: 780px;
          margin: 0px auto 50px;

          @media ($max992) {
            margin: 40px auto 0;
            width: 100%;
            height: 600px;
            margin: -60px auto 0;

            @media ($max640) {
              margin: 40px auto 0;
              width: 100%;
              height: auto;
            }
          }
        }

        img {
          height: 100%;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%) scale(1);
          transform-origin: 50% 50%;

          @media ($max640) {
          position: unset;
          transform: unset;
          transform-origin: unset;
          width: 100%;
          height: auto;
          }
        }
      }

    }
  }
}

.park_anim_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin: 200px auto 0;
  background-color: rgb(35, 35, 35);
  height: 365px;
  overflow: hidden;

  @media ($max1024) {
    grid-template-columns: 1fr;
    height: unset;
    margin: 100px auto;

    @media ($max640) {
      margin: 150px auto 40px;
    }
  }

  .park_anim_counter {
    display: flex;
    padding: 30px;

    @media ($max1024) {
      height: 365px;
      justify-content: center;
      overflow: hidden;

      @media ($max640) {
        height: 260px;
        margin-top: -120px;
      }
    }

    .counter_num_1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 50px;
    }

    .counter_num_2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 50px;
    }

    .counter_num_3 {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 50px;
    }

    .counter_number {
      font: normal normal 600 270px/250px $font-family_2;

      @media ($max640) {
        font-size: 100px;
      }
    }

    .final_number {
      color: rgb(48, 76, 103)
    }
  }

  .park_text_wrapper {
    padding: 70px 60px;
    border-left: 1px solid rgba(255, 255, 255, 0.15);

    @media ($max640) {
      padding: 40px 20px;
    }

    .park_heading {
      font: normal normal 500 40px/52px $font-family_1;
      color: #fff;

      @media ($max640) {
        font-size: 22px;
        line-height: 30px;
      }
    }

    .park_cont_1 {
      font: normal normal 500 13px/21px $font-family_1;
      color: #fff;
      margin-top: 20px;
      padding-right: 50px;
    }

    .park_cont_2 {
      font: normal normal 500 9px/12px $font-family_1;
      color: #fff;
      margin-top: 20px;
      padding-right: 55px;
    }
  }
}

footer {
  background-color: #eef0f2;

  .container {
    .layer1 {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media ($max640) {
        flex-direction: column-reverse;
        align-items: center;
        gap: 40px 0;
        width: 100%;
      }

      .logos {
        display: flex;
        gap: 0 20px;

        // align-items: center
        .wall_b_logo {
          mix-blend-mode: darken;
        }
      }

      .menu {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 32px 30px;
        color: #11171e;
        font-size: 12px;
        text-transform: uppercase;

        @media ($max640) {
          grid-gap: 20px 0;
          width: 100%;
        }
      }
    }

    .layer2 {
      margin-top: 60px;
      display: flex;
      justify-content: space-between;

      @media ($max640) {
        margin-top: 40px;
        flex-direction: column;
        gap: 20px 0;
        text-align: center;
      }

      .reraWrapper {
        color: #949494;
        font-size: 12px;

        a {
          color: #949494;
        }
      }

      .legaltxt {
        font-size: 14px;

        @media ($max640) {
          text-align: center;
        }
      }
    }

    .layer3 {
      margin-top: 40px;

      p {
        font-size: 14px;

        @media ($max640) {
          text-align: center;
        }

      }
    }
  }
}



.cont_box {
  background-color: rgb(234, 236, 239);
  box-shadow: rgba(0, 0, 0, 0.15) 5px 0px 36px -9px;
  width: 200px;
  height: 233px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;

  @media ($max1024) {
    width: 160px;
    height: 200px;

    @media ($max992) {
      height: 160px;

      @media ($max640) {
        width: 140px;
        height: 160px;
      }
    }
  }

  .cont_num {
    font: normal normal 500 22px/34px $font-family_1;
    color: rgb(48, 76, 103);
  }

  .cont_text {
    font: normal normal 400 10px/16px $font-family_1;
    color: rgb(51, 51, 51);
    letter-spacing: 0.1px;
  }
}

.tab_controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
}

.marquee_sec {
  position: relative;
  margin-bottom: 150px;
  @media ($max640) {
    margin-bottom: 0;
  }

  .marquee_wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 40px 0;

    .marquee_div {
      display: flex;
      align-items: center;
      gap: 50px;
      white-space: nowrap;
      width: 200%; // Double the width to accommodate the duplicated content

      // Right to Left animation for odd
      &:nth-child(odd):not(.animationmid) {
        animation: marquee-right 25s linear infinite;
      }

      // Left to Right animation for even
      &:nth-child(even):not(.animationmid) {
        animation: marquee-left 25s linear infinite;
      }

      // Centered position for divs with class "animationOff"
      &.animationmid {
        animation: marquee-right 50s linear infinite;
      }

      .marquee_txt {
        font: normal normal 16px/34px $font-family_1;
        color: rgb(51, 51, 51);
        background-color: #fff;
        padding: 10px 25px;
        border-radius: 40px;
        text-transform: uppercase;
        display: inline-block;
        @media ($max640) {
          padding: 10px 25px;
          font-size: 10px;
          line-height: 14px;
        }
      }

      // &:hover {
      //   animation-play-state: paused;
      // }
    }
  }

  .chips-top-wrapper {
        /* width: 100%; */
    /* height: 100%; */
    justify-content: center;
    align-items: center;
    /* display: flex; */
    position: absolute;
    top: 50%;
    /* bottom: 0; */
    left: 50%;
    transform: translate(-50%, -50%);
    /* right: 0; */

    .wall-circle {
      width: 23.7em;
      height: 23.7em;
      opacity: 1;
      -webkit-backdrop-filter: contrast(200%) blur(5px);
      backdrop-filter: contrast(200%) blur(5px);
      background-color: rgba(109, 110, 113, .2);
      border-radius: 100%;
      justify-content: center;
      align-items: center;
      display: flex;
      box-shadow: 0 60px 100px 40px rgba(116, 116, 116, .2), 0 -60px 100px 40px rgba(165, 165, 165, .2);
      @media ($max640) {
        width: 14em;
        height: 14em;
      }

      .wall-inner-circle {
        width: 15em;
        height: 15em;
        -webkit-backdrop-filter: brightness(84%) blur(90px);
        backdrop-filter: brightness(84%) blur(90px);
        background-color: rgba(247, 247, 247, .52);
        border-radius: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        @media ($max640) {
          width: 8em;
          height: 8em;
          -webkit-backdrop-filter: brightness(85%) blur(120px);
          backdrop-filter: brightness(85%) blur(120px);
        }

        .wall-logo.circles-logo {
          width: 50%;
          @media ($max640) {
            width: 95%;
          }
        }
      }
    }
  }
}

// Keyframes for right-to-left animation
@keyframes marquee-right {
  0% {
    transform: translateX(0); // Start from the initial position
  }

  100% {
    transform: translateX(-50%); // Move left until the duplicate set appears
  }
}

// Keyframes for left-to-right animation
@keyframes marquee-left {
  0% {
    transform: translateX(-50%); // Start from the middle (showing second set)
  }

  100% {
    transform: translateX(0); // Move right until the original set appears
  }
}

.rera_qr {
  width: 150px;
  mix-blend-mode: darken;
}
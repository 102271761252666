@font-face {
  font-family: "MADE Mirage";
  src: url("../../assets/mirage/MADEMirage-Thin.eot");
  src: url("../../assets/mirage/MADEMirage-Thin.eot?#iefix") format("embedded-opentype"), url("../../assets/mirage/MADEMirage-Thin.woff2") format("woff2"), url("../../assets/mirage/MADEMirage-Thin.woff") format("woff"), url("../../assets/mirage/MADEMirage-Thin.ttf") format("truetype"), url("../../assets/mirage/MADEMirage-Thin.svg#MADEMirage-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Euclid Circular b";
  src: url("https://uploads-ssl.webflow.com/62e761bef7a2ce5ed53f56d4/62e761bef7a2ce9b823f56f0_Euclid%20Circular%20B%20Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Euclid Circular b";
  src: url("https://uploads-ssl.webflow.com/62e761bef7a2ce5ed53f56d4/62e761bef7a2cee6433f56ef_Euclid%20Circular%20B%20Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Euclid Circular b";
  src: url("https://uploads-ssl.webflow.com/62e761bef7a2ce5ed53f56d4/62e761bef7a2ce91f23f56f1_Euclid%20Circular%20B%20Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Euclid Circular b";
  src: url("https://uploads-ssl.webflow.com/62e761bef7a2ce5ed53f56d4/62e761bef7a2ce185d3f56f3_Euclid%20Circular%20B%20Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Made Mirage";
  src: url("https://uploads-ssl.webflow.com/62e761bef7a2ce5ed53f56d4/62e761bef7a2ce0a1b3f56ee_MADE%20Mirage%20Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
.grid_container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
}

.col_1 {
  grid-column: auto/span 1;
}

.col_2 {
  grid-column: auto/span 2;
}

.col_3 {
  grid-column: auto/span 3;
}

.col_4 {
  grid-column: auto/span 4;
}

.col_5 {
  grid-column: auto/span 5;
}

.col_6 {
  grid-column: auto/span 6;
}

.col_7 {
  grid-column: auto/span 7;
}

.col_8 {
  grid-column: auto/span 8;
}

.col_9 {
  grid-column: auto/span 9;
}

.col_10 {
  grid-column: auto/span 10;
}

.col_11 {
  grid-column: auto/span 11;
}

.col_12 {
  grid-column: auto/span 12;
}

.text_uppercase {
  text-transform: uppercase;
}

@media (max-width:640px) {
  .col_xs_1 {
    grid-column: auto/span 1;
  }
  .col_xs_2 {
    grid-column: auto/span 2;
  }
  .col_xs_3 {
    grid-column: auto/span 3;
  }
  .col_xs_4 {
    grid-column: auto/span 4;
  }
  .col_xs_5 {
    grid-column: auto/span 5;
  }
  .col_xs_6 {
    grid-column: auto/span 6;
  }
  .col_xs_7 {
    grid-column: auto/span 7;
  }
  .col_xs_8 {
    grid-column: auto/span 8;
  }
  .col_xs_9 {
    grid-column: auto/span 9;
  }
  .col_xs_10 {
    grid-column: auto/span 10;
  }
  .col_xs_11 {
    grid-column: auto/span 11;
  }
  .col_xs_12 {
    grid-column: auto/span 12;
  }
}
/*Header Section*/
/*.bar1{width: 70% !important;}
      .bar2{width: 100% !important;}
      .bar3{width: 60% !important;}*/
/* NAV LINKS COMPRESS ANIM ------------------------------------------------- */
/*Footer Section*/
html {
  padding: 0;
  margin: 0;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font-family: "Euclid Circular B";
  font-weight: 400;
  color: #4d4c4f;
  line-height: 1.4;
  overflow-x: hidden;
  letter-spacing: 0.5px;
}

html.lenis {
  height: auto;
}

.lenis .lenis-smooth {
  scroll-behavior: auto;
}
.lenis .lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}
.lenis .lenis-stopped {
  overflow: hidden;
}
.lenis .lenis-scrolling iframe {
  pointer-events: none;
}

body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font-family: "Euclid Circular B";
  font-weight: 400;
  color: #4d4c4f;
  line-height: 1.4;
  overflow-x: hidden;
  letter-spacing: 0.5px;
  background-color: hsla(204, 8%, 87%, 0.45);
}

a {
  text-decoration: none;
  outline: none;
  border: 0;
}
a:focus {
  text-decoration: none !important;
}
a:hover {
  text-decoration: none !important;
}

img {
  text-decoration: none;
  outline: none;
  border: 0;
}

button {
  text-decoration: none;
  outline: none;
  border: 0;
  font-family: "Euclid Circular B";
}

input[type=submit] {
  text-decoration: none;
  outline: none;
  border: 0;
}

input {
  font-family: "Euclid Circular B";
}

select {
  font-family: "Euclid Circular B";
}

textarea {
  font-family: "Euclid Circular B";
}

b {
  font-weight: 700;
}

strong {
  font-weight: 700;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

p {
  padding: 0;
  margin: 0;
  font-weight: unset;
}

ul {
  padding: 0;
  margin: 0;
  font-weight: unset;
}

h1 {
  padding: 0;
  margin: 0;
  font-weight: unset;
}

h2 {
  padding: 0;
  margin: 0;
  font-weight: unset;
}

h3 {
  padding: 0;
  margin: 0;
  font-weight: unset;
}

h4 {
  padding: 0;
  margin: 0;
  font-weight: unset;
}

h5 {
  padding: 0;
  margin: 0;
  font-weight: unset;
}

h6 {
  padding: 0;
  margin: 0;
  font-weight: unset;
}

hr {
  padding: 0;
  margin: 0;
  font-weight: unset;
}

figure {
  padding: 0;
  margin: 0;
  font-weight: unset;
}

ol {
  padding: 0;
  margin: 0;
  font-weight: unset;
}

.container {
  max-width: 78.91em;
  margin: 0px auto;
  padding: 0 15px;
}
@media (max-width:1440px) {
  .container {
    max-width: 65.91em;
  }
}
@media (max-width:1440px) and (max-width:992px) {
  .container {
    width: 98%;
  }
}

.container-fluid {
  max-width: 95%;
  margin: 0px auto;
  padding: 0 15px;
}

.container.small {
  max-width: 850px;
  padding: 0 20px;
}

.container.medium {
  max-width: 1000px;
  padding: 0 20px;
}

.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-italic {
  font-style: italic;
}

.text-underline {
  text-decoration: underline;
}

.text-light {
  font-weight: 300;
}

.text-medium {
  font-weight: 400;
}

.text-bold {
  font-weight: 600;
}

.text-bolder {
  font-weight: 700;
}

.text-black {
  color: #000 !important;
}

.text-white {
  color: #fff !important;
}

.text-orange {
  color: #ee6e25;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}

.img-full {
  width: 100%;
  height: auto;
}

.center-block {
  margin: 0 auto;
}

.bg-white {
  background-color: #fff;
}

.bg-off-white {
  background-color: #f8f8fa;
}

.bg-cover {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.col-1 {
  width: 8.3333333333%;
  float: left;
}

.col-2 {
  width: 16.6666666667%;
  float: left;
}

.col-3 {
  width: 25%;
  float: left;
}

.col-4 {
  width: 33.3333333333%;
  float: left;
}

.col-5 {
  width: 41.6666666667%;
  float: left;
}

.col-6 {
  width: 50%;
  float: left;
}

.col-7 {
  width: 58.3333333333%;
  float: left;
}

.col-8 {
  width: 66.6666666667%;
  float: left;
}

.col-9 {
  width: 75%;
  float: left;
}

.col-10 {
  width: 83.3333333333%;
  float: left;
}

.col-11 {
  width: 91.6666666667%;
  float: left;
}

.col-12 {
  width: 100%;
  float: left;
}

.col-12-5 {
  width: 20%;
  float: left;
}

.w100 {
  width: 100%;
}

.w80 {
  width: 80%;
}

.w70 {
  width: 70%;
  float: left;
}

.w30 {
  width: 30%;
  float: left;
}

.w20 {
  width: 20%;
  float: left;
}

.cred {
  color: red;
}

.h100 {
  height: 100%;
}

.mg-5 {
  margin: 5px;
}

.mg-10 {
  margin: 10px;
}

.mg-20 {
  margin: 20px;
}

.mg-30 {
  margin: 30px;
}

.mg-tp-5 {
  margin-top: 5px;
}

.mg-tp-10 {
  margin-top: 10px;
}

.mg-tp-20 {
  margin-top: 20px;
}

.mg-tp-30 {
  margin-top: 30px;
}

.mg-bt-5 {
  margin-bottom: 5px;
}

.mg-bt-10 {
  margin-bottom: 10px;
}

.mg-bt-20 {
  margin-bottom: 20px;
}

.mg-bt-30 {
  margin-bottom: 30px;
}

.mg-rt-5 {
  margin-right: 5px;
}

.mg-rt-10 {
  margin-right: 10px;
}

.mg-rt-20 {
  margin-right: 20px;
}

.mg-rt-30 {
  margin-right: 30px;
}

.mg-lt-5 {
  margin-left: 5px;
}

.mg-lt-10 {
  margin-left: 10px;
}

.mg-lt-20 {
  margin-left: 20px;
}

.mg-lt-30 {
  margin-left: 30px;
}

.pd-5 {
  padding: 5px;
}

.pd-10 {
  padding: 10px;
}

.pd-20 {
  padding: 20px;
}

.pd-30 {
  padding: 30px;
}

.pd-tp-5 {
  padding-top: 5px;
}

.pd-tp-10 {
  padding-top: 10px;
}

.pd-tp-20 {
  padding-top: 20px;
}

.pd-tp-30 {
  padding-top: 30px;
}

.pd-bt-5 {
  padding-bottom: 5px;
}

.pd-bt-10 {
  padding-bottom: 10px;
}

.pd-bt-20 {
  padding-bottom: 20px;
}

.pd-bt-30 {
  padding-bottom: 30px;
}

.pd-rt-5 {
  padding-right: 5px;
}

.pd-rt-10 {
  padding-right: 10px;
}

.pd-rt-20 {
  padding-right: 20px;
}

.pd-rt-30 {
  padding-right: 30px;
}

.pd-lt-5 {
  padding-left: 5px;
}

.pd-lt-10 {
  padding-left: 10px;
}

.pd-lt-20 {
  padding-left: 20px;
}

.pd-lt-30 {
  padding-left: 30px;
}

.h500 {
  height: 500px;
}

.h600 {
  height: 600px;
}

.h700 {
  height: 700px;
}

.h800 {
  height: 800px;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.pos-fix {
  position: fixed;
}

.bottom {
  bottom: 0;
}

.top {
  top: 0;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.visible-md {
  display: none !important;
}

.visible-sm {
  display: none !important;
}

.visible-xs {
  display: none !important;
}

.hidden-xs {
  display: block;
}

.center-block {
  float: unset;
  margin: auto;
}

.row:before {
  display: table;
  content: " ";
}
.row:after {
  display: table;
  content: " ";
  clear: both;
}

.clearfix {
  clear: both;
}

.disptable {
  display: table;
  height: 100%;
}

.dispblock {
  display: block;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
}

.Yoverflowhidden {
  overflow-y: hidden;
}

.Xoverflowhidden {
  overflow-x: hidden;
}

.overflowhidden {
  overflow: hidden;
}

section {
  float: left;
  width: 100%;
}

section.padding {
  padding: 70px 0;
}

header {
  background-color: rgb(220, 223, 225);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 10px 0;
  z-index: 7;
  transition: all 0.6s ease-in-out;
}
header .upper .number-wrapper {
  margin-right: 20px;
  margin-top: 10px;
}
header .upper .ctc-wrapper {
  margin-right: 20px;
}
header .upper .enq-btn-wrapper a {
  display: inline-block;
  padding: 0px 15px;
  color: #fff;
  background-color: #6b6b6b;
  text-transform: uppercase;
  line-height: 40px;
}
header .lower .nav-links {
  text-align: center;
  transform: translateY(6px);
}
header .lower .nav-links a {
  font-family: "Euclid Circular B";
  display: inline-block;
  margin-right: 20px;
  margin-top: 10px;
  color: #000;
  position: relative;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
}
header .lower .nav-links a:last-child {
  margin-right: 0px;
}
header .lower .nav-links a.active {
  color: #6b6b6b;
}
header .header_enq_wrapper {
  transform: translateY(14px);
}
header .form-container {
  position: absolute;
  width: 300px;
  top: calc(100% + 10px);
  right: 15px;
  background-color: #efece7;
  padding: 10px;
  display: none;
}
header .form-container .frmclose {
  display: none;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #000;
  font-size: 20px;
}
header .form-container .thankyou {
  margin-bottom: 10px;
  margin-bottom: 10px;
}

.call-icon svg {
  width: 20px;
  height: 20px;
  position: relative;
  right: 2px;
  top: 4px;
}

.brand-logo img {
  width: 100px;
}

footer img {
  width: 180px;
}

.enquire-btn, .theme-btn {
  background-color: transparent;
  color: #000 !important;
  font-size: 14px;
  padding: 14px 20px;
  font-weight: 400;
  border: 1px solid #000;
  line-height: 16px;
  cursor: pointer;
}
@media (max-width:1024px) {
  .enquire-btn, .theme-btn {
    padding: 10px;
  }
}

.eq-height-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.home-banner {
  background-image: url("https://dummyimage.com/1366X700/6b6b6b/565659.png&text=banner");
}

.bar1 {
  transition: 0.4s;
}

.bar2 {
  transition: 0.4s;
}

.bar3 {
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-11px, 4px);
  transform: rotate(-45deg) translate(-5px, 5px);
  width: 28px !important;
}
.change .bar2 {
  opacity: 0;
}
.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -2px);
  transform: rotate(45deg) translate(-4px, -6px);
  width: 28px !important;
}

footer {
  float: left;
  width: 100%;
  color: #333;
}
footer.padding {
  padding: 70px 0;
  padding-bottom: 45px;
  background-color: #e0e1e1;
}
footer a {
  color: #333;
}
footer .upper {
  background-color: #2b2b2b;
  padding: 10px 0;
}

/* ------------------------------ text animation code starts ---------------------------  */
.revealOnScroll {
  opacity: 0;
}

.animated {
  -moz-animation-duration: 1.2s;
  -o-animation-duration: 1.2s;
  -webkit-animation-duration: 1.2s;
  -ms-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    -o-transform: translate3d(0, -100%, 0);
    -moz-transform: translate3d(0, -100%, 0);
    -ms-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    -o-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    transform: none;
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  -ms-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  -ms-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    -o-transform: translate3d(0, 100%, 0);
    -ms-transform: translate3d(0, 100%, 0);
    -moz-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -moz-transform: none;
    transform: none;
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  -ms-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    -o-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    -ms-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    -moz-transform: none;
    -o-transform: none;
    transform: none;
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  -o-animation-name: fadeInRight;
  -ms-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -o-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
    -o-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    transform: none;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  -ms-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  animation-name: fadeIn;
}

/* ends here  */
.section_title {
  font-size: 16px;
  line-height: 32px;
  text-transform: uppercase;
  font-weight: 300;
  color: #11171e;
}

.section_heading {
  color: #11171e;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 38px;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  font-weight: 300;
  color: #11171e;
}
@media (max-width:1024px) {
  .section_heading {
    font-size: 30px;
    line-height: 42px;
  }
}
@media (max-width:640px) {
  .section_heading {
    font-size: 26px;
    line-height: normal;
  }
}
@media (max-width: 375px) {
  .section_heading {
    font-size: 24px;
  }
}

.section_sub_heading {
  font-size: 14px;
  color: rgb(17, 23, 30);
  line-height: 18px;
  font-weight: 600;
}
@media (max-width:992px) {
  .section_sub_heading {
    font-size: 15px;
    font-weight: 500;
  }
}

.sectionTitle {
  letter-spacing: 0.3em;
  text-transform: uppercase;
  font-weight: 300;
  color: #11171e;
  font-size: 42px;
  line-height: 56px;
}
.sectionTitle span {
  letter-spacing: 0.3em;
  text-transform: uppercase;
  font-weight: 300;
  color: #11171e;
  font-size: 16px;
  line-height: 26px;
}
@media (max-width:992px) {
  .sectionTitle {
    font-size: 28px;
    line-height: 32px;
  }
}

.contentBox .sectionTitle {
  font-size: 38px;
}
@media (max-width:992px) {
  .contentBox .sectionTitle {
    font-size: 32px;
    line-height: normal;
  }
}

.section_content {
  font-size: 12px;
  line-height: 18px;
  color: rgb(51, 51, 51);
  font-weight: 400;
}
@media (max-width:640px) {
  .section_content {
    font-size: 16px;
    line-height: 26px;
  }
}

.prefix {
  color: #304c67;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 26px;
}

.visit_number_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.visit_number_wrapper .first_num {
  font: normal normal 700 170px/170px "MADE Mirage";
  color: rgb(212, 214, 216);
}
@media (max-width:992px) {
  .visit_number_wrapper {
    scale: 0.7;
  }
}
@media (max-width:992px) and (max-width:640px) {
  .visit_number_wrapper {
    display: none;
  }
}
.visit_number_wrapper .scroll_num_cont {
  overflow: hidden;
  height: 170px;
  right: 0;
  z-index: 4;
  bottom: -200px;
}
.visit_number_wrapper .scroll_num_cont .scroll_num_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: fit-content;
}
.visit_number_wrapper .scroll_num_cont .scroll_num_wrapper .scroll_num_in {
  font: normal normal 700 170px/170px "MADE Mirage";
  color: rgb(212, 214, 216);
  position: relative;
}
.visit_number_wrapper .scroll_num_cont .scroll_num_wrapper .scroll_num_fi {
  font: normal normal 700 170px/170px "MADE Mirage";
  color: rgb(212, 214, 216);
  position: relative;
}
.visit_number_wrapper .scroll_text_cont {
  position: absolute;
  z-index: 6;
}
.visit_number_wrapper .scroll_text_cont .scroll_text_wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 30px;
  overflow: hidden;
}
.visit_number_wrapper .scroll_text_cont .scroll_text_wrapper .scroll_text_in {
  font: normal normal 500 12px/30px "Euclid Circular B";
  color: rgb(153, 153, 153);
  position: relative;
  text-transform: uppercase;
}
.visit_number_wrapper .scroll_text_cont .scroll_text_wrapper .scroll_text_fi {
  font: normal normal 500 12px/30px "Euclid Circular B";
  color: rgb(153, 153, 153);
  position: relative;
  text-transform: uppercase;
}

.scroll_down_arr_wrapper {
  margin-top: 50px;
  height: 22px;
  overflow-y: hidden;
}
@media (max-width:992px) {
  .scroll_down_arr_wrapper {
    margin-top: 20px;
  }
}
.scroll_down_arr_wrapper .scroll_down_txt {
  font: normal normal 400 14px/22px "Euclid Circular B";
  color: rgb(48, 76, 103);
  display: inline-block;
  position: relative;
}
.scroll_down_arr_wrapper .scroll_down_txt span {
  display: inline-block;
}
.scroll_down_arr_wrapper .scroll_down_txt span .down_arrow {
  width: 20px;
  animation: arrow-down 1.5s infinite;
}

@keyframes arrow-down {
  0% {
    transform: translateY(-22px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(22px);
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
#location input[type=checkbox],
#location input[type=radio] {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  clip: rect(0, 0, 0, 0);
  visibility: hidden;
  opacity: 0;
  transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  z-index: -1;
}
#location input[type=checkbox][id=test1]:checked ~ .tabs .tab label[for=test1],
#location input[type=radio][id=test1]:checked ~ .tabs .tab label[for=test1] {
  opacity: 1;
  cursor: default;
  transition: all 0.4s ease-in-out;
}
#location input[type=checkbox][id=test1]:checked ~ .panels #panel1,
#location input[type=radio][id=test1]:checked ~ .panels #panel1 {
  opacity: 1;
  display: block;
  animation: fade-in 1s ease-in-out;
}
#location input[type=checkbox][id=test1]:not(:checked) ~ .panels #panel1,
#location input[type=radio][id=test1]:not(:checked) ~ .panels #panel1 {
  opacity: 0;
  display: none;
  animation: fade-out 1s ease-in-out;
}
#location input[type=checkbox][id=test2]:checked ~ .tabs .tab label[for=test2],
#location input[type=radio][id=test2]:checked ~ .tabs .tab label[for=test2] {
  opacity: 1;
  cursor: default;
  transition: all 0.4s ease-in-out;
}
#location input[type=checkbox][id=test2]:checked ~ .panels #panel2,
#location input[type=radio][id=test2]:checked ~ .panels #panel2 {
  opacity: 1;
  display: block;
  animation: fade-in 1s ease-in-out;
}
#location input[type=checkbox][id=test2]:not(:checked) ~ .panels #panel2,
#location input[type=radio][id=test2]:not(:checked) ~ .panels #panel2 {
  opacity: 0;
  display: none;
  animation: fade-out 1s ease-in-out;
}
#location input[type=checkbox][id=test3]:checked ~ .tabs .tab label[for=test3],
#location input[type=radio][id=test3]:checked ~ .tabs .tab label[for=test3] {
  opacity: 1;
  cursor: default;
  transition: all 0.4s ease-in-out;
}
#location input[type=checkbox][id=test3]:checked ~ .panels #panel3,
#location input[type=radio][id=test3]:checked ~ .panels #panel3 {
  opacity: 1;
  display: block;
  animation: fade-in 1s ease-in-out;
}
#location input[type=checkbox][id=test3]:not(:checked) ~ .panels #panel3,
#location input[type=radio][id=test3]:not(:checked) ~ .panels #panel3 {
  opacity: 0;
  display: none;
  animation: fade-out 1s ease-in-out;
}
#location input[type=checkbox][id=test4]:checked ~ .tabs .tab label[for=test4],
#location input[type=radio][id=test4]:checked ~ .tabs .tab label[for=test4] {
  opacity: 1;
  cursor: default;
  transition: all 0.4s ease-in-out;
}
#location input[type=checkbox][id=test4]:checked ~ .panels #panel4,
#location input[type=radio][id=test4]:checked ~ .panels #panel4 {
  opacity: 1;
  display: block;
  animation: fade-in 1s ease-in-out;
}
#location input[type=checkbox][id=test4]:not(:checked) ~ .panels #panel4,
#location input[type=radio][id=test4]:not(:checked) ~ .panels #panel4 {
  opacity: 0;
  display: none;
  animation: fade-out 1s ease-in-out;
}

#location .tabs-panel .tabs {
  display: flex;
  transition: all 0.4s ease-in-out;
}
@media (max-width:640px) {
  #location .tabs-panel .tabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
#location .tabs-panel .tabs .tab {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  flex-basis: auto;
  flex-grow: 1;
  position: relative;
  width: 60%;
  margin-top: 2.5rem;
  margin-right: 0;
  text-align: center;
  color: #707a89;
  background-color: transparent;
  cursor: pointer;
  transition: backgroundcolor 1.5s ease-in-out;
}
@media (max-width:1024px) {
  #location .tabs-panel .tabs .tab {
    width: unset;
  }
}
#location .tabs-panel .tabs .tab label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
  height: 100%;
  padding: 0 0.75rem;
  border: none;
  cursor: pointer;
  color: rgb(34, 34, 34);
  opacity: 0.6;
}
#location .tabs-panel .tabs .tab label:hover {
  color: rgb(34, 34, 34);
  opacity: 0.8;
}
#location .tabs-panel .separator {
  width: 100%;
  padding-top: 40px;
  border-bottom: 1px solid rgb(169, 171, 174);
}
#location .tabs-panel .panels {
  border: none;
}
#location .tabs-panel .panels .tab-panel {
  overflow: hidden;
  opacity: 0;
  display: none;
  transition: opacity 1.5s ease-in-out;
}
#location .tabs-panel .panels .tab-panel .content {
  font: normal normal 500 16px/26px "Euclid Circular B";
  color: #2B2B2B;
}

#gallery input[type=checkbox],
#gallery input[type=radio] {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  clip: rect(0, 0, 0, 0);
  visibility: hidden;
  opacity: 0;
  transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  z-index: -1;
}
#gallery input[type=checkbox][id=galltest1]:checked ~ .galltabs .galltab label[for=galltest1],
#gallery input[type=radio][id=galltest1]:checked ~ .galltabs .galltab label[for=galltest1] {
  opacity: 1;
  cursor: default;
  transition: all 0.4s ease-in-out;
}
#gallery input[type=checkbox][id=galltest1]:checked ~ .panelsGall #gallpanel1,
#gallery input[type=radio][id=galltest1]:checked ~ .panelsGall #gallpanel1 {
  opacity: 1;
  display: block;
  animation: fade-in 1s ease-in-out;
}
#gallery input[type=checkbox][id=galltest1]:not(:checked) ~ .panelsGall #gallpanel1,
#gallery input[type=radio][id=galltest1]:not(:checked) ~ .panelsGall #gallpanel1 {
  opacity: 0;
  display: none;
  animation: fade-out 1s ease-in-out;
}
#gallery input[type=checkbox][id=galltest2]:checked ~ .galltabs .galltab label[for=galltest2],
#gallery input[type=radio][id=galltest2]:checked ~ .galltabs .galltab label[for=galltest2] {
  opacity: 1;
  cursor: default;
  transition: all 0.4s ease-in-out;
}
#gallery input[type=checkbox][id=galltest2]:checked ~ .panelsGall #gallpanel2,
#gallery input[type=radio][id=galltest2]:checked ~ .panelsGall #gallpanel2 {
  opacity: 1;
  display: block;
  animation: fade-in 1s ease-in-out;
}
#gallery input[type=checkbox][id=galltest2]:not(:checked) ~ .panelsGall #gallpanel2,
#gallery input[type=radio][id=galltest2]:not(:checked) ~ .panelsGall #gallpanel2 {
  opacity: 0;
  display: none;
  animation: fade-out 1s ease-in-out;
}

#gallery .tabs-panel-gall .galltabs {
  display: flex;
  transition: all 0.4s ease-in-out;
}
@media (max-width:640px) {
  #gallery .tabs-panel-gall .galltabs {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
#gallery .tabs-panel-gall .galltabs .galltab {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  flex-basis: auto;
  flex-grow: 1;
  position: relative;
  width: 60%;
  margin-top: 2.5rem;
  margin-right: 0;
  text-align: center;
  color: #707a89;
  background-color: transparent;
  cursor: pointer;
  transition: backgroundcolor 1.5s ease-in-out;
}
@media (max-width:1024px) {
  #gallery .tabs-panel-gall .galltabs .galltab {
    width: unset;
  }
}
#gallery .tabs-panel-gall .galltabs .galltab label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
  height: 100%;
  padding: 0 0.75rem;
  border: none;
  cursor: pointer;
  color: rgb(34, 34, 34);
  opacity: 0.6;
}
#gallery .tabs-panel-gall .galltabs .galltab label:hover {
  color: rgb(34, 34, 34);
  opacity: 0.8;
}
#gallery .tabs-panel-gall .separator {
  width: 100%;
  padding-top: 40px;
  border-bottom: 1px solid rgb(169, 171, 174);
}
#gallery .tabs-panel-gall .panelsGall {
  border: none;
}
#gallery .tabs-panel-gall .panelsGall .tab-panel-gall {
  opacity: 0;
  display: none;
  transition: opacity 1.5s ease-in-out;
}
#gallery .tabs-panel-gall .panelsGall .tab-panel-gall .content {
  font: normal normal 500 16px/26px "Euclid Circular B";
  color: #2B2B2B;
}

.themeBtn {
  color: #e5e8eb;
  background-color: #232323;
  padding: 10px 18px;
  font-size: 18px;
}

.themeBtn, #submitBtn, .theme-btn {
  cursor: pointer;
}

.scroll-trigger {
  cursor: pointer;
}

/* Overlay Background */
.overlay {
  display: none; /* Hidden by default */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Transparent black */
  z-index: 1000;
}

/* Popup Content */
.popup-content {
  position: relative;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  border-radius: 10px;
  max-width: 600px;
  width: 90%;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

/* Close Icon */
.close-icon {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
}

.dispInBlock {
  display: inline-block;
}

.geeta_bulders {
  width: 200px;
}

/* Container for each row */
.form-row {
  margin-bottom: 15px;
}

/* Flexbox structure */
.form-group {
  display: flex;
  flex-direction: column;
  position: relative;
}

/* Label styling */
.form-group label {
  font-size: 14px;
  margin-bottom: 5px;
  color: #333;
}

/* Input styling */
.form-group input[type=text],
.form-group input[type=email],
.form-group input[type=tel],
.form-group input[type=submit] {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Submit button styling */
.themeBtn {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  border-radius: 4px;
}

.themeBtn:hover {
  background-color: #0056b3;
}

/* Error message styling */
.form-group label.error {
  position: absolute;
  color: red;
  left: 5px;
  bottom: -15px;
  font-size: 9px;
}

#image1,
#image2 {
  width: 100%;
  height: 100vh;
}

#first_visit {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  background-color: hsl(204, 8%, 87%);
}
#first_visit .logo_wrapper {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
#first_visit .logo_wrapper img {
  width: 200px;
  mix-blend-mode: darken;
}

.medium-sub-heading {
  color: #11171e;
  letter-spacing: 0.02em;
  font-size: 1.04em;
  font-weight: 500;
  line-height: 1.4;
}

.medium-sub-heading.home-hero-bottom {
  max-width: 40ch;
}

#image2 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
#image2 .banner_slide {
  width: 100%;
  height: 100vh;
}
@media (max-width:1024px) {
  #image2 .banner_slide {
    height: 100vh;
  }
}

.medium-sub-heading {
  color: #11171e;
  letter-spacing: 0.02em;
  font-size: 1.04em;
  font-weight: 500;
  line-height: 1.4;
  margin-bottom: 20px;
}

.medium-sub-heading.home-hero-bottom {
  max-width: 40ch;
}

.home_banner {
  background-color: #eef0f2;
  position: relative;
  z-index: 4;
  min-height: 100vh;
}
@media (max-width:640px) {
  .home_banner .grid_container {
    display: flex;
    flex-direction: column-reverse;
  }
}
@media (max-width:640px) {
  .home_banner .container {
    padding: unset;
  }
}
.home_banner .banner_left_cont {
  flex-direction: column;
  align-items: flex-start;
  padding-top: 90px;
  display: flex;
}
@media (max-width:992px) {
  .home_banner .banner_left_cont {
    padding-top: 80px;
  }
}
@media (max-width:992px) and (max-width:640px) {
  .home_banner .banner_left_cont {
    padding: 20px 20px;
  }
}
.home_banner .banner_left_cont .banner_btm_line {
  width: 135%;
  height: 1px;
  background-color: #adafb1;
  margin: 50px -64px;
}
@media (max-width:1024px) {
  .home_banner .banner_left_cont .banner_btm_line {
    margin: 30px 0;
  }
}
.home_banner .banner_image_container {
  position: relative;
  z-index: 2;
  width: 503px;
  height: 640px;
  overflow: hidden;
  margin: 10px auto;
}
@media (max-width:1024px) {
  .home_banner .banner_image_container {
    width: 350px;
    height: 470px;
    margin: 50px 0;
  }
}
@media (max-width:1024px) and (max-width:992px) {
  .home_banner .banner_image_container {
    width: 300px;
    height: 380px;
  }
}
@media (max-width:1024px) and (max-width:992px) and (max-width:640px) {
  .home_banner .banner_image_container {
    width: 100%;
    height: 400px;
    margin-top: 0;
  }
}
.home_banner .banner_image_container img {
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) scale(1);
  transform-origin: 50% 50%;
}
.home_banner .visit_number_wrapper {
  right: -150px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}
@media (max-width:1024px) {
  .home_banner .visit_number_wrapper {
    right: 0px;
  }
}
@media (max-width:992px) {
  .home_banner .visit_number_wrapper {
    right: -60px;
  }
}
.home_banner .visit_number_wrapper .first_num {
  font: normal normal 700 170px/170px "MADE Mirage";
  color: rgb(212, 214, 216);
}
.home_banner .visit_number_wrapper .scroll_num_cont {
  overflow: hidden;
  height: 170px;
  right: 0;
  z-index: 4;
  bottom: -200px;
}
.home_banner .visit_number_wrapper .scroll_num_cont .scroll_num_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: fit-content;
}
.home_banner .visit_number_wrapper .scroll_num_cont .scroll_num_wrapper .banner_scroll_num_in {
  font: normal normal 700 170px/170px "MADE Mirage";
  color: rgb(212, 214, 216);
  position: relative;
}
.home_banner .visit_number_wrapper .scroll_num_cont .scroll_num_wrapper .banner_scroll_num_fi {
  font: normal normal 700 170px/170px "MADE Mirage";
  color: rgb(212, 214, 216);
  position: relative;
}
.home_banner .visit_number_wrapper .scroll_text_cont {
  position: absolute;
  z-index: 6;
}
.home_banner .visit_number_wrapper .scroll_text_cont .scroll_text_wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 30px;
  overflow: hidden;
}
.home_banner .visit_number_wrapper .scroll_text_cont .scroll_text_wrapper .banner_scroll_text_in {
  font: normal normal 500 12px/30px "Euclid Circular B";
  color: rgb(153, 153, 153);
  position: relative;
}
.home_banner .visit_number_wrapper .scroll_text_cont .scroll_text_wrapper .banner_scroll_text_fi {
  font: normal normal 500 12px/30px "Euclid Circular B";
  color: rgb(153, 153, 153);
  position: relative;
}
.home_banner .future_cont_wrapper {
  background-color: rgb(35, 35, 35);
  padding: 140px 0;
  margin-top: -70px;
  z-index: -1;
  width: 110%;
  position: relative;
  left: -4%;
}
@media (max-width:1024px) {
  .home_banner .future_cont_wrapper {
    margin-top: -50px;
  }
}
@media (max-width:1024px) and (max-width:992px) {
  .home_banner .future_cont_wrapper {
    margin-top: -30px;
    padding: 60px 20px 90px;
  }
}
@media (max-width:640px) {
  .home_banner .future_cont_wrapper {
    margin-top: 0;
    padding: 80px 30px;
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 40px auto;
    left: 0;
  }
}
.home_banner .future_cont_wrapper .section_heading {
  color: rgb(230, 231, 232);
  width: 530px;
  margin: auto;
  padding-left: 40px;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 38px;
}
@media (max-width:1024px) {
  .home_banner .future_cont_wrapper .section_heading {
    width: 370px;
  }
}
@media (max-width:640px) {
  .home_banner .future_cont_wrapper .section_heading {
    width: 100%;
    font-size: 27px;
    padding-right: 0px;
    padding-left: 0;
  }
}
.home_banner .future_cont_wrapper .section_content {
  color: rgb(230, 231, 232);
  max-width: 575px;
  margin: auto;
}
@media (max-width:1024px) {
  .home_banner .future_cont_wrapper .section_content {
    margin-left: auto;
  }
}
@media (max-width:640px) {
  .home_banner .future_cont_wrapper .section_content {
    width: 100%;
    margin: 20px 0 0 0;
    padding-right: 0;
  }
}
@media (max-width:640px) {
  .home_banner .overview_container {
    display: flex;
    flex-direction: column;
  }
}
.home_banner .overview_container .overview_text_wrapper {
  padding-top: 103px;
  padding-left: 100px;
}
@media (max-width:1024px) {
  .home_banner .overview_container .overview_text_wrapper {
    padding-top: 30px;
  }
}
@media (max-width:1024px) and (max-width:640px) {
  .home_banner .overview_container .overview_text_wrapper {
    padding: 30px 40px;
  }
}
.home_banner .overview_container .overview_text_wrapper .section_heading {
  color: rgb(17, 23, 30);
}
.home_banner .overview_container .overview_text_wrapper .section_content {
  color: rgb(35, 35, 35);
  padding-top: 25px;
}
.home_banner .overview_container .overview_text_wrapper .ovr_specs_wrapper {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
@media (max-width:640px) {
  .home_banner .overview_container .overview_text_wrapper .ovr_specs_wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
.home_banner .overview_container .overview_text_wrapper .ovr_specs_wrapper .ovr_specs_cont {
  padding: 30px 15px 30px 20px;
  border: 1px solid rgb(212, 214, 216);
  width: 173px;
}
@media (max-width:1024px) {
  .home_banner .overview_container .overview_text_wrapper .ovr_specs_wrapper .ovr_specs_cont {
    padding: 20px 15px 20px 20px;
  }
}
@media (max-width:1024px) and (max-width:992px) {
  .home_banner .overview_container .overview_text_wrapper .ovr_specs_wrapper .ovr_specs_cont {
    padding: 25px;
    width: 100%;
  }
}
@media (max-width:1024px) and (max-width:992px) and (max-width:640px) {
  .home_banner .overview_container .overview_text_wrapper .ovr_specs_wrapper .ovr_specs_cont {
    padding: 25px;
    width: 100%;
  }
}
.home_banner .overview_container .overview_text_wrapper .ovr_specs_wrapper .ovr_specs_cont .tower_height {
  width: 35px;
  height: 35px;
}
.home_banner .overview_container .overview_text_wrapper .ovr_specs_wrapper .ovr_specs_cont .spec_heading {
  font: normal normal 500 9px/12px "Euclid Circular B";
  color: rgb(109, 110, 113);
  text-transform: uppercase;
}
@media (max-width:640px) {
  .home_banner .overview_container .overview_text_wrapper .ovr_specs_wrapper .ovr_specs_cont .spec_heading {
    font-size: 10px;
    line-height: 20px;
    padding-top: 5px;
  }
}
.home_banner .overview_container .overview_text_wrapper .ovr_specs_wrapper .ovr_specs_cont .spec_main {
  font: normal normal 500 22px/28px "Euclid Circular B";
  color: rgb(48, 76, 103);
  letter-spacing: 1px;
}
@media (max-width:992px) {
  .home_banner .overview_container .overview_text_wrapper .ovr_specs_wrapper .ovr_specs_cont .spec_main {
    font-size: 16px;
    line-height: 22px;
  }
}
@media (max-width:992px) and (max-width:640px) {
  .home_banner .overview_container .overview_text_wrapper .ovr_specs_wrapper .ovr_specs_cont .spec_main {
    font-size: 22px;
    line-height: 28px;
    padding-top: 5px;
  }
}
.home_banner .overview_container .overview_image_container {
  position: relative;
  z-index: 2;
  width: 445px;
  height: 586px;
  overflow: hidden;
  margin: 75px auto 50px;
}
@media (max-width:1024px) {
  .home_banner .overview_container .overview_image_container {
    width: 360px;
    height: 450px;
  }
}
@media (max-width:1024px) and (max-width:992px) {
  .home_banner .overview_container .overview_image_container {
    width: 320px;
    height: 400px;
  }
}
@media (max-width:1024px) and (max-width:992px) and (max-width:640px) {
  .home_banner .overview_container .overview_image_container {
    margin: 30px auto;
    width: 80%;
  }
}
.home_banner .overview_container .overview_image_container img {
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) scale(1.4);
  transform-origin: 50% 50%;
}
@media (max-width:640px) {
  .home_banner .overview_container .overview_image_container img {
    width: 100%;
    height: auto;
  }
}
.home_banner .ovr_conclusion_text_wrap .ovr_conclusion_text {
  font: normal normal 500 14px/22px "Euclid Circular B";
  color: rgb(17, 23, 30);
  width: 470px;
  margin: 20px 0 120px 35px;
}
@media (max-width:1024px) {
  .home_banner .ovr_conclusion_text_wrap .ovr_conclusion_text {
    width: 370px;
    margin: 40px 0 120px 20px;
  }
}
@media (max-width:1024px) and (max-width:992px) {
  .home_banner .ovr_conclusion_text_wrap .ovr_conclusion_text {
    width: 340px;
  }
}
@media (max-width:1024px) and (max-width:992px) and (max-width:640px) {
  .home_banner .ovr_conclusion_text_wrap .ovr_conclusion_text {
    width: 100%;
    font-size: 16px;
    margin: 40px 0;
    padding: 0 20px;
  }
}

.location {
  background-color: rgb(229, 232, 235);
  padding-bottom: 0 !important;
}
@media (max-width:640px) {
  .location .location_cont_1 {
    display: flex;
    flex-direction: column;
    padding: 30px;
  }
}
.location .location_cont_1 .section_content {
  color: rgb(35, 35, 35);
  margin-top: 40px;
  width: 375px;
}
@media (max-width:640px) {
  .location .location_cont_1 .section_content {
    width: 100%;
  }
}
.location .location_cont_1 .loc_img_wrapper {
  margin-top: -250px;
  position: relative;
  z-index: 6;
}
@media (max-width:640px) {
  .location .location_cont_1 .loc_img_wrapper {
    margin: 40px 0 0;
  }
}
.location .location_cont_1 .loc_img_wrapper .loc_image_container {
  position: relative;
  z-index: 2;
  width: 443px;
  height: 591px;
  overflow: hidden;
  margin: -50px auto 50px;
}
@media (max-width:1024px) {
  .location .location_cont_1 .loc_img_wrapper .loc_image_container {
    width: 350px;
    height: 480px;
    margin: -60px 0 50px;
  }
}
@media (max-width:1024px) and (max-width:640px) {
  .location .location_cont_1 .loc_img_wrapper .loc_image_container {
    margin: 40px auto 0;
    width: 100%;
  }
}
.location .location_cont_1 .loc_img_wrapper .loc_image_container img {
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) scale(1);
  transform-origin: 50% 50%;
}
@media (max-width:640px) {
  .location .location_cont_1 .loc_img_wrapper .loc_image_container img {
    width: 100%;
    height: auto;
  }
}
.location .location_cont_1 .visit_number_wrapper {
  position: absolute;
  bottom: 0;
  right: -130px;
}
@media (max-width:1024px) {
  .location .location_cont_1 .visit_number_wrapper {
    right: 0;
  }
}
@media (max-width:992px) {
  .location .location_cont_1 .visit_number_wrapper {
    right: -60px;
  }
}
.location .loc_points_wrapper {
  margin-top: 120px;
}
@media (max-width:992px) {
  .location .loc_points_wrapper {
    margin-top: 80px;
  }
}
@media (max-width:992px) and (max-width:640px) {
  .location .loc_points_wrapper {
    margin-top: 20px;
  }
}
.location .loc_points_wrapper .section_heading {
  color: rgb(17, 23, 30);
  text-align: center;
  letter-spacing: 1px;
}
.location .loc_points_wrapper .section_content {
  color: rgb(51, 51, 51);
  text-align: center;
  width: 370px;
  margin: 25px auto 80px;
}
@media (max-width:992px) {
  .location .loc_points_wrapper .section_content {
    margin: 25px auto 40px;
  }
}
@media (max-width:640px) {
  .location .loc_points_wrapper .section_content {
    width: unset;
  }
}
.location .loc_points_wrapper .loc_points_cont {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 60px 20px;
  width: 750px;
  margin: auto;
}
@media (max-width:992px) {
  .location .loc_points_wrapper .loc_points_cont {
    scale: 0.8;
  }
}
@media (max-width:992px) and (max-width:640px) {
  .location .loc_points_wrapper .loc_points_cont {
    scale: 1;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 40px;
    margin-top: 20px;
  }
}
.location .loc_points_wrapper .loc_points_cont .loc_points {
  text-align: center;
}
.location .loc_points_wrapper .loc_points_cont .loc_points .loc_image {
  width: 54px;
  height: auto;
}
@media (max-width:640px) {
  .location .loc_points_wrapper .loc_points_cont .loc_points .loc_image {
    width: 64px;
  }
}
.location .loc_points_wrapper .loc_points_cont .loc_points .loc_desc {
  font: normal normal 500 14px/18px "Euclid Circular B";
  color: rgb(51, 51, 51);
  margin-top: 5px;
}
@media (max-width:640px) {
  .location .loc_points_wrapper .loc_points_cont .loc_points .loc_desc {
    font-size: 16px;
    line-height: 24px;
  }
}
.location .loc_specs_wrapper {
  margin-top: 80px;
  width: 100%;
  position: relative;
  z-index: 6;
}
.location .loc_specs_wrapper .tabs-panel .tab_label {
  font: normal normal 400 14px/18px "Euclid Circular B";
  color: rgb(34, 34, 34);
  text-transform: uppercase;
}
.location .loc_specs_wrapper .tabs-panel .panels {
  padding: 60px 0;
  min-height: 300px;
}
.location .loc_specs_wrapper .tabs-panel .panels .tabs_panel_wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  align-items: center;
  gap: 30px 20px;
}
@media (max-width:640px) {
  .location .loc_specs_wrapper .tabs-panel .panels .tabs_panel_wrapper {
    padding-bottom: 20px;
  }
}
.location .loc_specs_wrapper .tabs-panel .panels .tabs_panel_wrapper .cont_box {
  background-color: rgb(234, 236, 239);
  box-shadow: rgba(0, 0, 0, 0.15) 5px 0px 36px -9px;
  width: 200px;
  height: 233px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}
@media (max-width:1024px) {
  .location .loc_specs_wrapper .tabs-panel .panels .tabs_panel_wrapper .cont_box {
    width: 160px;
    height: 200px;
  }
}
@media (max-width:1024px) and (max-width:992px) {
  .location .loc_specs_wrapper .tabs-panel .panels .tabs_panel_wrapper .cont_box {
    height: 160px;
  }
}
@media (max-width:1024px) and (max-width:992px) and (max-width:640px) {
  .location .loc_specs_wrapper .tabs-panel .panels .tabs_panel_wrapper .cont_box {
    width: 233px;
    height: 273px;
  }
}
.location .loc_specs_wrapper .tabs-panel .panels .tabs_panel_wrapper .cont_box .cont_num {
  font: normal normal 500 22px/34px "Euclid Circular B";
  color: rgb(48, 76, 103);
}
.location .loc_specs_wrapper .tabs-panel .panels .tabs_panel_wrapper .cont_box .cont_text {
  font: normal normal 400 10px/16px "Euclid Circular B";
  color: rgb(51, 51, 51);
  letter-spacing: 0.1px;
}
.location .loc_map_wrapper_main {
  position: relative;
  z-index: 2;
  margin-top: 0px;
}
@media (max-width:1024px) {
  .location .loc_map_wrapper_main {
    margin-top: -20px;
    margin-bottom: -30px;
  }
}
@media (max-width:1024px) and (max-width:640px) {
  .location .loc_map_wrapper_main {
    margin-top: 40px;
  }
}
.location .loc_map_wrapper_main .loc_map_overlay {
  position: absolute;
  background-color: #e5e8eb;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: start;
}
.location .loc_map_wrapper_main .loc_map_overlay .map_overlay_cont {
  padding-top: 90px;
}
.location .loc_map_wrapper_main .loc_map_overlay .map_overlay_cont .section_content {
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: rgb(51, 51, 51);
  font-weight: 500;
  width: 645px;
}
@media (max-width:640px) {
  .location .loc_map_wrapper_main .loc_map_overlay .map_overlay_cont .section_content {
    width: 90%;
    margin: auto;
  }
}
.location .loc_map_wrapper_main .map_image_wrapper {
  max-width: 1920px;
  margin: auto;
  width: 100%;
  height: auto;
  aspect-ratio: 16/8;
}
@media (max-width:1024px) {
  .location .loc_map_wrapper_main .map_image_wrapper {
    height: 100%;
    aspect-ratio: unset;
  }
}
.location .loc_map_wrapper_main .map_image_wrapper img {
  width: 100%;
  transform: translateY(0px);
}
@media (max-width:1024px) {
  .location .loc_map_wrapper_main .map_image_wrapper img {
    transform: unset;
    background-color: #fff;
  }
}

.gallery {
  background-color: #eef0f2;
  position: relative;
}
.gallery.padding {
  padding-bottom: 0px;
}
@media (max-width:640px) {
  .gallery.padding {
    padding-bottom: 50px;
  }
}
@media (max-width:640px) {
  .gallery .mobCont {
    padding: 0 15px;
  }
}
.gallery .title {
  letter-spacing: 12px;
  margin: 20px auto 80px;
  text-align: left;
  width: fit-content;
}
.gallery .tabs_panel_wrapper .gallBox {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0 20px;
  transition: transform 0.5s ease-in-out;
  padding: 0 50px;
  max-width: 1640px;
  margin: 30px auto;
}
@media (max-width:640px) {
  .gallery .tabs_panel_wrapper .gallBox {
    grid-template-columns: auto;
    grid-gap: 10px 0;
  }
}
.gallery .tabs_panel_wrapper .gallBox img {
  aspect-ratio: 3/2;
}
.gallery .tabs_panel_wrapper .gallBox a {
  display: block;
  transition: transform 0.5s ease-in-out;
}
.gallery .tabs_panel_wrapper .gallBox:hover {
  /* This is the default hover behavior. */
  /* The jQuery will override this based on mouse position dynamically. */
  transform: translateX(0);
}
.gallery .dbroWrap {
  padding-top: 30px;
}
.gallery .dbroWrap form {
  padding-top: 30px;
}
.gallery .dbroWrap form .frow {
  justify-content: center;
}
.gallery .dbroWrap form input[type=email] {
  height: 100%;
  padding: 2px 10px !important;
}
.gallery .dbroWrap .thankyouWrap {
  padding-top: 30px;
}
.gallery .dbroWrap .thankyouWrap .theme-btn {
  display: inline-block;
  margin-top: 20px;
}

#dbroForm .form-group {
  padding-bottom: 5px;
}

@media (max-width:640px) {
  .walkthrough {
    margin-top: 50px;
  }
}
.walkthrough .floatCounter {
  position: absolute;
  top: 30%;
  right: 10px;
  z-index: 2;
}
@media (max-width:992px) {
  .walkthrough .floatCounter {
    right: 0;
  }
}
.walkthrough .banner-video {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  margin-top: 40px;
  position: relative;
  z-index: 5;
}

.amenities .floatCounter {
  position: absolute;
  top: 70px;
  right: 30px;
}
@media (max-width:992px) {
  .amenities .floatCounter {
    right: 0;
  }
}
.amenities .container .grid_container {
  align-items: center;
}
.amenities .container .grid_container.cont2 {
  margin-top: 50px;
}
.amenities .container .grid_container.cont2 .section_sub_heading {
  font: normal normal 500 14px/22px Euclid Circular B;
}
@media (max-width:1024px) {
  .amenities .container .grid_container.cont2 {
    margin-top: 20px;
  }
  .amenities .container .grid_container.cont2 .iconWrapper {
    margin-top: 30px;
  }
}
@media (max-width:640px) {
  .amenities .container .grid_container.cont2 .imgBox {
    margin-top: 20px;
  }
}
.amenities .container .grid_container .contentBox {
  display: grid;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}
.amenities .container .grid_container .contentBox.paddingRight {
  padding-right: 30px;
}
@media (max-width:992px) {
  .amenities .container .grid_container .contentBox.paddingRight {
    padding-right: 40px;
  }
}
.amenities .container .grid_container .contentBox.paddingLeft {
  padding-left: 70px;
}
@media (max-width:992px) {
  .amenities .container .grid_container .contentBox.paddingLeft {
    padding-left: 40px;
  }
}
@media (max-width:640px) {
  .amenities .container .grid_container .contentBox.paddingLeft {
    padding-left: 0;
  }
}
.amenities .container .grid_container .contentBox .iconWrapper {
  display: flex;
  justify-content: space-between;
  gap: 0 55px;
}
@media (max-width:992px) {
  .amenities .container .grid_container .contentBox .iconWrapper {
    gap: 0 20px;
    align-items: center;
  }
}
@media (max-width:640px) {
  .amenities .container .grid_container .contentBox .iconWrapper {
    margin-top: 20px;
    flex-direction: column;
    gap: 20px 0;
  }
}
.amenities .container .grid_container .contentBox .iconWrapper .iconCont .svgWrap svg {
  height: 65px;
  width: auto;
  aspect-ratio: 5/4;
}
.amenities .container .grid_container .contentBox .iconWrapper .iconCont .infoTitle {
  letter-spacing: 0.02em;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.57;
  padding: 10px 0;
  border-bottom: 1px solid #adafb1;
}
@media (max-width:992px) {
  .amenities .container .grid_container .contentBox .iconWrapper .iconCont .infoTitle {
    font-size: 16px;
    font-weight: 500;
  }
}
.amenities .container .grid_container .contentBox .iconWrapper .iconCont .info {
  margin-top: 10px;
  letter-spacing: 0.02em;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.57;
}
@media (max-width:992px) {
  .amenities .container .grid_container .contentBox .iconWrapper .iconCont .info {
    font-size: 14px;
  }
}

.torchEffect {
  background-color: #fff;
  position: relative;
  overflow: hidden;
}
.torchEffect .mouseEffectRow {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  z-index: 2;
}
.torchEffect .mouseEffectRow.visible {
  background-color: #fff;
  justify-content: center;
}
.torchEffect .mouseEffectRow.visible .mouseEffectText {
  color: #fff;
  mix-blend-mode: exclusion;
}
.torchEffect .mouseEffectRow .mouseEffectText {
  color: #fff;
  font-size: 28px;
  font-weight: 500;
  line-height: 1.5;
  position: relative;
  z-index: 2;
  transition: color 0.6s ease-in-out;
}

.circleMask {
  position: absolute;
  background-color: #232323;
  border-radius: 50%;
  width: 300px;
  height: 300px;
  z-index: 1;
  pointer-events: none;
  display: none;
}

.lounges {
  background-color: #eef0f2;
}
.lounges .container .loungeBox {
  display: grid;
  grid-template-columns: 26% auto;
  grid-gap: 0 60px;
}
@media (max-width:640px) {
  .lounges .container .loungeBox {
    grid-template-columns: auto;
    grid-gap: 20px 0;
  }
}
.lounges .container .loungeBox.cont2 {
  margin-top: 50px;
}
.lounges .container .loungeBox.cont2 .section_sub_heading {
  font: normal normal 500 14px/22px Euclid Circular B;
}
@media (max-width:1024px) {
  .lounges .container .loungeBox.cont2 {
    margin-top: 20px;
  }
  .lounges .container .loungeBox.cont2 .iconWrapper {
    margin-top: 30px;
  }
}
@media (max-width:640px) {
  .lounges .container .loungeBox.cont2 .imgBox {
    margin-top: 20px;
  }
}
.lounges .container .loungeBox .contentBox {
  display: grid;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}
.lounges .container .loungeBox .contentBox.paddingRight {
  padding-right: 30px;
}
@media (max-width:992px) {
  .lounges .container .loungeBox .contentBox.paddingRight {
    padding-right: 40px;
  }
}
.lounges .container .loungeBox .contentBox.paddingLeft {
  padding-left: 70px;
}
@media (max-width:992px) {
  .lounges .container .loungeBox .contentBox.paddingLeft {
    padding-left: 40px;
  }
}
@media (max-width:640px) {
  .lounges .container .loungeBox .contentBox.paddingLeft {
    padding-left: 0;
  }
}
.lounges .container .loungeBox .contentBox .iconWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0 30px;
}
@media (max-width:992px) {
  .lounges .container .loungeBox .contentBox .iconWrapper {
    gap: 0 20px;
    align-items: center;
  }
}
@media (max-width:992px) and (max-width:640px) {
  .lounges .container .loungeBox .contentBox .iconWrapper {
    align-items: start;
  }
}
@media (max-width:640px) {
  .lounges .container .loungeBox .contentBox .iconWrapper {
    margin-top: 20px;
    flex-direction: column;
    gap: 20px 0;
  }
}
.lounges .container .loungeBox .contentBox .iconWrapper .iconCont .svgWrap svg {
  height: 65px;
  width: auto;
  aspect-ratio: 5/4;
}
.lounges .container .loungeBox .contentBox .iconWrapper .iconCont .infoTitle {
  letter-spacing: 0.02em;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.57;
  padding: 10px 0;
  border-bottom: 1px solid #adafb1;
}
@media (max-width:992px) {
  .lounges .container .loungeBox .contentBox .iconWrapper .iconCont .infoTitle {
    font-size: 16px;
    font-weight: 500;
  }
}
.lounges .container .loungeBox .contentBox .iconWrapper .iconCont .info {
  margin-top: 10px;
  letter-spacing: 0.02em;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.57;
}
@media (max-width:992px) {
  .lounges .container .loungeBox .contentBox .iconWrapper .iconCont .info {
    font-size: 14px;
  }
}
.lounges .container .loungeBox .imgBox {
  position: relative;
  z-index: 2;
  width: 805px;
  height: 453px;
  overflow: hidden;
  margin: -50px auto 50px;
}
@media (max-width:1024px) {
  .lounges .container .loungeBox .imgBox {
    width: 620px;
    height: 480px;
    margin: 20px auto 50px;
  }
}
@media (max-width:1024px) and (max-width:992px) {
  .lounges .container .loungeBox .imgBox {
    width: 100%;
    height: 380px;
  }
}
@media (max-width:1024px) and (max-width:992px) and (max-width:640px) {
  .lounges .container .loungeBox .imgBox {
    margin: 40px auto 0;
    width: 100%;
    height: auto;
  }
}
.lounges .container .loungeBox .imgBox img {
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) scale(1.4);
  transform-origin: 50% 50%;
}
@media (max-width:640px) {
  .lounges .container .loungeBox .imgBox img {
    width: 100%;
    height: auto;
    position: unset;
    transform: unset;
    transform-origin: unset;
  }
}

.gHeights {
  background-color: #eef0f2;
}
.gHeights .gHeightsBox {
  display: grid;
  grid-template-columns: 55% auto;
  grid-gap: 0 80px;
  align-items: center;
}
@media (max-width:992px) {
  .gHeights .gHeightsBox {
    grid-gap: 0 40px;
  }
}
@media (max-width:640px) {
  .gHeights .gHeightsBox {
    grid-template-columns: auto;
    grid-gap: 20px 0;
  }
}
@media (max-width:992px) {
  .gHeights .gHeightsBox .imgBox img {
    aspect-ratio: 1/1;
  }
}
.gHeights .gHeightsBox .contentBox .prefix {
  color: #304c67;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 26px;
}
.gHeights .gHeightsBox .contentBox .infoTitle {
  margin-top: 20px;
  font-weight: 300;
}
.gHeights .gHeightsBox .contentBox .info {
  margin-top: 34px;
  font-size: 12px;
}
@media (max-width:992px) {
  .gHeights .gHeightsBox .contentBox .info {
    margin-top: 15px;
    font-size: 16px;
  }
}

.bWider .container.bgBlack {
  background-color: #232323;
  padding-top: 100px;
}
.bWider .container .title {
  text-align: center;
  color: #fff;
  margin-bottom: 600px;
}
@media (max-width:640px) {
  .bWider .container .title {
    margin-bottom: 300px;
  }
}
.bWider .container .longerBuild {
  margin-top: -500px;
}
@media (max-width:1024px) {
  .bWider .container .longerBuild {
    margin-top: -560px;
  }
}
@media (max-width:640px) {
  .bWider .container .longerBuild {
    margin-top: -250px;
  }
}
.bWider .container .longerBuild img {
  width: 60%;
}
@media (max-width:1024px) {
  .bWider .container .longerBuild img {
    width: 70%;
  }
}
@media (max-width:992px) {
  .bWider .container .longerBuild img {
    width: 80%;
  }
}
@media (max-width:640px) {
  .bWider .container .longerBuild img {
    width: 90%;
  }
}
.bWider .bottomMain .section_sub_heading {
  font-weight: 500;
}

.stories {
  background-color: #eef0f2;
}
.stories .container {
  position: relative;
}
.stories .container .visit_number_wrapper {
  position: absolute;
  top: 120px;
  right: -30px;
}
@media (max-width:1024px) {
  .stories .container .visit_number_wrapper {
    right: 0;
  }
}
@media (max-width:992px) {
  .stories .container .visit_number_wrapper {
    right: -60px;
  }
}
.stories .container .storiesBox {
  display: grid;
  grid-template-columns: 40% auto;
  grid-gap: 0 120px;
}
@media (max-width:992px) {
  .stories .container .storiesBox {
    grid-gap: 0 40px;
  }
}
@media (max-width:640px) {
  .stories .container .storiesBox {
    grid-template-columns: auto;
    grid-gap: 20px 0;
  }
}
.stories .container .storiesBox .contentBox .iconWrapper {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  gap: 0 80px;
}
@media (max-width:992px) {
  .stories .container .storiesBox .contentBox .iconWrapper {
    margin-top: 20px;
    gap: 0 30px;
    align-items: center;
  }
}
@media (max-width:992px) and (max-width:992px) {
  .stories .container .storiesBox .contentBox .iconWrapper {
    flex-direction: column;
    gap: 20px;
  }
}
.stories .container .storiesBox .contentBox .iconWrapper .iconCont .svgWrap svg {
  height: 65px;
  width: auto;
  aspect-ratio: 5/4;
}
.stories .container .storiesBox .contentBox .iconWrapper .iconCont .infoTitle {
  letter-spacing: 0.02em;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.57;
  padding: 10px 0;
  border-bottom: 1px solid #adafb1;
}
@media (max-width:992px) {
  .stories .container .storiesBox .contentBox .iconWrapper .iconCont .infoTitle {
    font-size: 16px;
    font-weight: 500;
  }
}
.stories .container .storiesBox .contentBox .iconWrapper .iconCont .info {
  margin-top: 10px;
  letter-spacing: 0.02em;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 1.57;
}
@media (max-width:992px) {
  .stories .container .storiesBox .contentBox .iconWrapper .iconCont .info {
    font-size: 14px;
  }
}

.workspace {
  background-color: #333;
}
.workspace .container {
  color: #fff;
}
.workspace .container .workSpaceBox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 60px;
}
@media (max-width:640px) {
  .workspace .container .workSpaceBox {
    grid-template-columns: auto;
    grid-gap: 10px 0;
  }
}
.workspace .container .workSpaceBox .contentBox {
  display: flex;
  flex-direction: column;
  gap: 60px 0;
  align-items: start;
}
@media (max-width:640px) {
  .workspace .container .workSpaceBox .contentBox {
    grid-gap: 20px 0;
  }
}
.workspace .container .workSpaceBox .contentBox .title {
  color: #fff;
}
.workspace .container .workSpaceBox .contentBox .formWrapper {
  padding-right: 100px;
  width: 100%;
}
@media (max-width:640px) {
  .workspace .container .workSpaceBox .contentBox .formWrapper {
    padding-right: 0;
  }
}
.workspace .container .workSpaceBox .contentBox .formWrapper form {
  display: grid;
  grid-gap: 25px 0;
}
.workspace .container .workSpaceBox .contentBox .formWrapper form .form-group label.error {
  bottom: -20px;
}
.workspace .container .workSpaceBox .contentBox .formWrapper form input {
  border: 1px solid #fff;
  background-color: transparent;
  padding: 10px;
  color: #fff;
  width: 100%;
}
.workspace .container .workSpaceBox .contentBox .formWrapper form input::placeholder {
  color: #fff;
}
.workspace .container .workSpaceBox .contentBox .formWrapper form textarea {
  border: 1px solid #fff;
  background-color: transparent;
  padding: 10px;
  color: #fff;
  width: 100%;
}
.workspace .container .workSpaceBox .contentBox .formWrapper form textarea::placeholder {
  color: #fff;
}
.workspace .container .workSpaceBox .imgBox {
  position: relative;
  z-index: 2;
  width: 555px;
  height: 875px;
  overflow: hidden;
  margin: -150px auto 50px;
}
@media (max-width:1024px) {
  .workspace .container .workSpaceBox .imgBox {
    width: 450px;
    height: 780px;
    margin: 0px auto 50px;
  }
}
@media (max-width:1024px) and (max-width:992px) {
  .workspace .container .workSpaceBox .imgBox {
    margin: 40px auto 0;
    width: 100%;
    height: 600px;
    margin: -60px auto 0;
  }
}
@media (max-width:1024px) and (max-width:992px) and (max-width:640px) {
  .workspace .container .workSpaceBox .imgBox {
    margin: 40px auto 0;
    width: 100%;
    height: auto;
  }
}
.workspace .container .workSpaceBox .imgBox img {
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) scale(1);
  transform-origin: 50% 50%;
}
@media (max-width:640px) {
  .workspace .container .workSpaceBox .imgBox img {
    position: unset;
    transform: unset;
    transform-origin: unset;
    width: 100%;
    height: auto;
  }
}

.park_anim_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin: 200px auto 0;
  background-color: rgb(35, 35, 35);
  height: 365px;
  overflow: hidden;
}
@media (max-width:1024px) {
  .park_anim_wrapper {
    grid-template-columns: 1fr;
    height: unset;
    margin: 100px auto;
  }
}
@media (max-width:1024px) and (max-width:640px) {
  .park_anim_wrapper {
    margin: 150px auto 40px;
  }
}
.park_anim_wrapper .park_anim_counter {
  display: flex;
  padding: 30px;
}
@media (max-width:1024px) {
  .park_anim_wrapper .park_anim_counter {
    height: 365px;
    justify-content: center;
    overflow: hidden;
  }
}
@media (max-width:1024px) and (max-width:640px) {
  .park_anim_wrapper .park_anim_counter {
    height: 260px;
    margin-top: -120px;
  }
}
.park_anim_wrapper .park_anim_counter .counter_num_1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}
.park_anim_wrapper .park_anim_counter .counter_num_2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}
.park_anim_wrapper .park_anim_counter .counter_num_3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
}
.park_anim_wrapper .park_anim_counter .counter_number {
  font: normal normal 600 270px/250px "MADE Mirage";
}
@media (max-width:640px) {
  .park_anim_wrapper .park_anim_counter .counter_number {
    font-size: 100px;
  }
}
.park_anim_wrapper .park_anim_counter .final_number {
  color: rgb(48, 76, 103);
}
.park_anim_wrapper .park_text_wrapper {
  padding: 70px 60px;
  border-left: 1px solid rgba(255, 255, 255, 0.15);
}
@media (max-width:640px) {
  .park_anim_wrapper .park_text_wrapper {
    padding: 40px 20px;
  }
}
.park_anim_wrapper .park_text_wrapper .park_heading {
  font: normal normal 500 40px/52px "Euclid Circular B";
  color: #fff;
}
@media (max-width:640px) {
  .park_anim_wrapper .park_text_wrapper .park_heading {
    font-size: 22px;
    line-height: 30px;
  }
}
.park_anim_wrapper .park_text_wrapper .park_cont_1 {
  font: normal normal 500 13px/21px "Euclid Circular B";
  color: #fff;
  margin-top: 20px;
  padding-right: 50px;
}
.park_anim_wrapper .park_text_wrapper .park_cont_2 {
  font: normal normal 500 9px/12px "Euclid Circular B";
  color: #fff;
  margin-top: 20px;
  padding-right: 55px;
}

footer {
  background-color: #eef0f2;
}
footer .container .layer1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width:640px) {
  footer .container .layer1 {
    flex-direction: column-reverse;
    align-items: center;
    gap: 40px 0;
    width: 100%;
  }
}
footer .container .layer1 .logos {
  display: flex;
  gap: 0 20px;
}
footer .container .layer1 .logos .wall_b_logo {
  mix-blend-mode: darken;
}
footer .container .layer1 .menu {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 32px 30px;
  color: #11171e;
  font-size: 12px;
  text-transform: uppercase;
}
@media (max-width:640px) {
  footer .container .layer1 .menu {
    grid-gap: 20px 0;
    width: 100%;
  }
}
footer .container .layer2 {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
}
@media (max-width:640px) {
  footer .container .layer2 {
    margin-top: 40px;
    flex-direction: column;
    gap: 20px 0;
    text-align: center;
  }
}
footer .container .layer2 .reraWrapper {
  color: #949494;
  font-size: 12px;
}
footer .container .layer2 .reraWrapper a {
  color: #949494;
}
footer .container .layer2 .legaltxt {
  font-size: 14px;
}
@media (max-width:640px) {
  footer .container .layer2 .legaltxt {
    text-align: center;
  }
}
footer .container .layer3 {
  margin-top: 40px;
}
footer .container .layer3 p {
  font-size: 14px;
}
@media (max-width:640px) {
  footer .container .layer3 p {
    text-align: center;
  }
}

.cont_box {
  background-color: rgb(234, 236, 239);
  box-shadow: rgba(0, 0, 0, 0.15) 5px 0px 36px -9px;
  width: 200px;
  height: 233px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}
@media (max-width:1024px) {
  .cont_box {
    width: 160px;
    height: 200px;
  }
}
@media (max-width:1024px) and (max-width:992px) {
  .cont_box {
    height: 160px;
  }
}
@media (max-width:1024px) and (max-width:992px) and (max-width:640px) {
  .cont_box {
    width: 140px;
    height: 160px;
  }
}
.cont_box .cont_num {
  font: normal normal 500 22px/34px "Euclid Circular B";
  color: rgb(48, 76, 103);
}
.cont_box .cont_text {
  font: normal normal 400 10px/16px "Euclid Circular B";
  color: rgb(51, 51, 51);
  letter-spacing: 0.1px;
}

.tab_controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
}

.marquee_sec {
  position: relative;
  margin-bottom: 150px;
}
@media (max-width:640px) {
  .marquee_sec {
    margin-bottom: 0;
  }
}
.marquee_sec .marquee_wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px 0;
}
.marquee_sec .marquee_wrap .marquee_div {
  display: flex;
  align-items: center;
  gap: 50px;
  white-space: nowrap;
  width: 200%;
}
.marquee_sec .marquee_wrap .marquee_div:nth-child(odd):not(.animationmid) {
  animation: marquee-right 25s linear infinite;
}
.marquee_sec .marquee_wrap .marquee_div:nth-child(even):not(.animationmid) {
  animation: marquee-left 25s linear infinite;
}
.marquee_sec .marquee_wrap .marquee_div.animationmid {
  animation: marquee-right 50s linear infinite;
}
.marquee_sec .marquee_wrap .marquee_div .marquee_txt {
  font: normal normal 16px/34px "Euclid Circular B";
  color: rgb(51, 51, 51);
  background-color: #fff;
  padding: 10px 25px;
  border-radius: 40px;
  text-transform: uppercase;
  display: inline-block;
}
@media (max-width:640px) {
  .marquee_sec .marquee_wrap .marquee_div .marquee_txt {
    padding: 10px 25px;
    font-size: 10px;
    line-height: 14px;
  }
}
.marquee_sec .chips-top-wrapper {
  /* width: 100%; */
  /* height: 100%; */
  justify-content: center;
  align-items: center;
  /* display: flex; */
  position: absolute;
  top: 50%;
  /* bottom: 0; */
  left: 50%;
  transform: translate(-50%, -50%);
  /* right: 0; */
}
.marquee_sec .chips-top-wrapper .wall-circle {
  width: 23.7em;
  height: 23.7em;
  opacity: 1;
  -webkit-backdrop-filter: contrast(200%) blur(5px);
  backdrop-filter: contrast(200%) blur(5px);
  background-color: rgba(109, 110, 113, 0.2);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0 60px 100px 40px rgba(116, 116, 116, 0.2), 0 -60px 100px 40px rgba(165, 165, 165, 0.2);
}
@media (max-width:640px) {
  .marquee_sec .chips-top-wrapper .wall-circle {
    width: 14em;
    height: 14em;
  }
}
.marquee_sec .chips-top-wrapper .wall-circle .wall-inner-circle {
  width: 15em;
  height: 15em;
  -webkit-backdrop-filter: brightness(84%) blur(90px);
  backdrop-filter: brightness(84%) blur(90px);
  background-color: rgba(247, 247, 247, 0.52);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
@media (max-width:640px) {
  .marquee_sec .chips-top-wrapper .wall-circle .wall-inner-circle {
    width: 8em;
    height: 8em;
    -webkit-backdrop-filter: brightness(85%) blur(120px);
    backdrop-filter: brightness(85%) blur(120px);
  }
}
.marquee_sec .chips-top-wrapper .wall-circle .wall-inner-circle .wall-logo.circles-logo {
  width: 50%;
}
@media (max-width:640px) {
  .marquee_sec .chips-top-wrapper .wall-circle .wall-inner-circle .wall-logo.circles-logo {
    width: 95%;
  }
}

@keyframes marquee-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
@keyframes marquee-left {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}
.rera_qr {
  width: 150px;
  mix-blend-mode: darken;
}

/* Large Devices, Wide Screens */
/* Large Devices, Wide Screens */
/* Medium Devices, Desktops */
/* Small Devices, Tablets */
/* Extra Small Devices, Phones */
/* Extra Small Devices, Phones */
/* Extra Small Devices, Phones */
@media only screen and (max-width: 1024px) {
  .col-md-1 {
    width: 8.3333333333%;
  }
  .col-md-2 {
    width: 16.6666666667%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.3333333333%;
  }
  .col-md-5 {
    width: 41.6666666667%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.3333333333%;
  }
  .col-md-8 {
    width: 66.6666666667%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.3333333333%;
  }
  .col-md-11 {
    width: 91.6666666667%;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-12-5 {
    width: 20%;
  }
  .hidden-md {
    display: none !important;
  }
  .visible-md {
    display: block !important;
  }
}
@media only screen and (max-width: 992px) {
  .col-sm-1 {
    width: 8.3333333333%;
  }
  .col-sm-2 {
    width: 16.6666666667%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.3333333333%;
  }
  .col-sm-5 {
    width: 41.6666666667%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.3333333333%;
  }
  .col-sm-8 {
    width: 66.6666666667%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.3333333333%;
  }
  .col-sm-11 {
    width: 91.6666666667%;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-12-5 {
    width: 20%;
  }
  .set-transform {
    transform: unset;
    transition: unset;
  }
  header .form-container.show {
    top: 0 !important;
    bottom: 0 !important;
    display: block !important;
  }
  header .nav-links {
    display: none;
    position: fixed;
    top: 60px;
    left: 0;
    background-color: #fff;
    width: 100%;
    height: 100%;
    z-index: 999;
    transition: unset;
  }
  header .nav-links a {
    display: block !important;
    text-align: center;
    line-height: 40px !important;
    color: #fff;
    margin-top: 0px;
    margin-right: 0px;
  }
  header .lower .nav-links a {
    margin-right: 0px;
    color: #000;
  }
  header .lower .nav-links a:last-child {
    margin-right: 0px;
  }
  header .upper .enq-btn-wrapper {
    margin-right: 50px;
    margin-top: 15px;
  }
  header .upper .ctc-wrapper {
    margin-right: 5px;
  }
  header .upper .number-wrapper {
    margin-right: 15px;
    margin-top: 25px;
  }
  .menu-icon-mobile {
    display: block;
    width: 50px;
    padding: 14px 8px 4px 8px;
    background-color: transparent;
    border-radius: 0px;
    margin: 0;
    position: absolute;
    top: 10px;
    right: 0;
    height: 40px;
  }
  .menu-icon-mobile > span {
    display: block;
    height: 2px;
    width: 60%;
    margin-bottom: 4px;
    background-color: #000;
  }
  .menu-icon-mobile.active > span:nth-child(1) {
    transform: rotate(45deg);
  }
  .menu-icon-mobile.active > span:nth-child(2) {
    transform: rotate(135deg);
    position: relative;
    top: -5px;
  }
  .menu-icon-mobile.active > span:nth-child(3) {
    display: none;
  }
  .hidden-sm {
    display: none !important;
  }
  .visible-sm {
    display: block !important;
  }
  section.padding {
    padding: 35px 0px;
  }
  #hid-on-mob {
    display: none !important;
  }
  .home-banner {
    background-image: url("https://dummyimage.com/768X1024/6b6b6b/565659&text=ipad-banner");
  }
}
@media only screen and (max-width: 640px) {
  body {
    -webkit-text-size-adjust: none;
  }
  .col-xs-1 {
    width: 8.3333333333%;
  }
  .col-xs-2 {
    width: 16.6666666667%;
  }
  .col-xs-3 {
    width: 25%;
  }
  .col-xs-4 {
    width: 33.3333333333%;
  }
  .col-xs-5 {
    width: 41.6666666667%;
  }
  .col-xs-6 {
    width: 50%;
  }
  .col-xs-7 {
    width: 58.3333333333%;
  }
  .col-xs-8 {
    width: 66.6666666667%;
  }
  .col-xs-9 {
    width: 75%;
  }
  .col-xs-10 {
    width: 83.3333333333%;
  }
  .col-xs-11 {
    width: 91.6666666667%;
  }
  .col-xs-12 {
    width: 100%;
  }
  .col-xs-12-5 {
    width: 20%;
  }
  .hidden-xs {
    display: none !important;
  }
  .visible-xs {
    display: block !important;
  }
  .menu-icon-mobile {
    height: 60px;
    padding: 25px 8px 4px 8px;
    top: -8px;
  }
  header {
    padding: 10px 0;
  }
  header .nav-links {
    padding: 10px 0;
    top: 55px;
  }
  header .brand-logo img {
    width: 85px;
  }
  header .form-container {
    position: fixed !important;
    left: 0 !important;
    right: 0 !important;
    top: 100% !important;
    bottom: -100% !important;
    width: 100% !important;
    padding: 30px 0 !important;
    -ms-transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    z-index: 300;
    background-color: rgba(255, 254, 254, 0.97) !important;
  }
  header .form-container .frmclose {
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #000;
    font-size: 20px;
  }
  header .form-container.show {
    top: 0 !important;
    bottom: 0 !important;
    display: block !important;
  }
  footer {
    padding-bottom: 30px;
  }
  footer .lower {
    font-size: 10px;
  }
  footer .lower div {
    text-align: center !important;
  }
  .mob-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: #fff;
    font-size: 14px;
    font-weight: 600;
    z-index: 999999;
  }
  .mob-footer a {
    display: block;
    line-height: 30px;
  }
  .mob-footer a.mob-enq-btn {
    background-color: #6b6b6b;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
  }
  .mob-footer a.mob-call-btn {
    background-color: #fff;
    color: #6b6b6b;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
  }
  .home-banner {
    background-image: url("https://dummyimage.com/320X480/6b6b6b/565659&text=mobile-banner");
  }
}
// @import url('https://fonts.cdnfonts.com/css/euclid-circular-b');

@font-face {
    font-family: 'MADE Mirage';
    src: url('../../assets/mirage/MADEMirage-Thin.eot');
    src: url('../../assets/mirage/MADEMirage-Thin.eot?#iefix') format('embedded-opentype'),
        url('../../assets/mirage/MADEMirage-Thin.woff2') format('woff2'),
        url('../../assets/mirage/MADEMirage-Thin.woff') format('woff'),
        url('../../assets/mirage/MADEMirage-Thin.ttf') format('truetype'),
        url('../../assets/mirage/MADEMirage-Thin.svg#MADEMirage-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
@font-face {
  font-family: 'Euclid Circular b';
  src: url('https://uploads-ssl.webflow.com/62e761bef7a2ce5ed53f56d4/62e761bef7a2ce9b823f56f0_Euclid%20Circular%20B%20Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Euclid Circular b';
  src: url('https://uploads-ssl.webflow.com/62e761bef7a2ce5ed53f56d4/62e761bef7a2cee6433f56ef_Euclid%20Circular%20B%20Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Euclid Circular b';
  src: url('https://uploads-ssl.webflow.com/62e761bef7a2ce5ed53f56d4/62e761bef7a2ce91f23f56f1_Euclid%20Circular%20B%20Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Euclid Circular b';
  src: url('https://uploads-ssl.webflow.com/62e761bef7a2ce5ed53f56d4/62e761bef7a2ce185d3f56f3_Euclid%20Circular%20B%20Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Made Mirage';
  src: url('https://uploads-ssl.webflow.com/62e761bef7a2ce5ed53f56d4/62e761bef7a2ce0a1b3f56ee_MADE%20Mirage%20Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
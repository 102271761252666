/* Container for each row */
.form-row {
    margin-bottom: 15px;
}

/* Flexbox structure */
.form-group {
    display: flex;
    flex-direction: column;
    position: relative;
}

/* Label styling */
.form-group label {
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
}

/* Input styling */
.form-group input[type="text"],
.form-group input[type="email"],
.form-group input[type="tel"],
.form-group input[type="submit"] {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Submit button styling */
.themeBtn {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 10px;
    border-radius: 4px;
}

.themeBtn:hover {
    background-color: #0056b3;
}

/* Error message styling */
.form-group label.error {
    position: absolute;
    color: red;
    left: 5px;
    bottom: -15px;
    font-size: 9px;
}

/*Header Section*/
/*.bar1{width: 70% !important;}
      .bar2{width: 100% !important;}
      .bar3{width: 60% !important;}*/
/* NAV LINKS COMPRESS ANIM ------------------------------------------------- */
/*Footer Section*/

html {
	padding: 0;
	margin: 0;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	font-family: $font-family_1;
	font-weight: 400;
	color: $color_1;
	line-height: 1.4;
	overflow-x: hidden;
	letter-spacing: 0.5px;
}

html.lenis {
	height: auto;
}

.lenis {
	.lenis-smooth {
		scroll-behavior: auto;
	}
	.lenis-smooth [data-lenis-prevent] {
		overscroll-behavior: contain;
	}
	.lenis-stopped {
		overflow: hidden;
	}
	.lenis-scrolling iframe {
		pointer-events: none;
	}
}

body {
	padding: 0;
	margin: 0;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	font-family: $font-family_1;
	font-weight: 400;
	color: $color_1;
	line-height: 1.4;
	overflow-x: hidden;
	letter-spacing: 0.5px;
	background-color:hsla(204,8%,87%,.45);
}
a {
	text-decoration: none;
	outline: none;
	border: 0;
	&:focus {
		text-decoration: none !important;
	}
	&:hover {
		text-decoration: none !important;
	}
}
img {
	text-decoration: none;
	outline: none;
	border: 0;
}
button {
	text-decoration: none;
	outline: none;
	border: 0;
	font-family: $font-family_1;
}
input[type=submit] {
	text-decoration: none;
	outline: none;
	border: 0;
}
input {
	font-family: $font-family_1;
}
select {
	font-family: $font-family_1;
}
textarea {
	font-family: $font-family_1;
}
b {
	font-weight: 700;
}
strong {
	font-weight: 700;
}
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	&:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	&:before {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
}
p {
	padding: 0;
	margin: 0;
	font-weight: unset;
}
ul {
	padding: 0;
	margin: 0;
	font-weight: unset;
}
h1 {
	padding: 0;
	margin: 0;
	font-weight: unset;
}
h2 {
	padding: 0;
	margin: 0;
	font-weight: unset;
}
h3 {
	padding: 0;
	margin: 0;
	font-weight: unset;
}
h4 {
	padding: 0;
	margin: 0;
	font-weight: unset;
}
h5 {
	padding: 0;
	margin: 0;
	font-weight: unset;
}
h6 {
	padding: 0;
	margin: 0;
	font-weight: unset;
}
hr {
	padding: 0;
	margin: 0;
	font-weight: unset;
}
figure {
	padding: 0;
	margin: 0;
	font-weight: unset;
}
ol {
	padding: 0;
	margin: 0;
	font-weight: unset;
}
.container {
	
	// max-width: 1020px;
	max-width: 78.91em;
	margin: 0px auto;
	padding: 0 15px;

	@media ($max1440) {
		max-width: 65.91em;

        @media ($max992) {
			width: 98%;
        }
	}
}
.container-fluid {
	max-width: 95%;
	margin: 0px auto;
	padding: 0 15px;
}
.container.small {
	max-width: 850px;
	padding: 0 20px;
}
.container.medium {
	max-width: 1000px;
	padding: 0 20px;
}
.pull-left {
	float: left !important;
}
.pull-right {
	float: right !important;
}
.text-left {
	text-align: left;
}
.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}
.text-justify {
	text-align: justify;
}
.text-uppercase {
	text-transform: uppercase;
}
.text-lowercase {
	text-transform: lowercase;
}
.text-italic {
	font-style: italic;
}
.text-underline {
	text-decoration: underline;
}
.text-light {
	font-weight: 300;
}
.text-medium {
	font-weight: 400;
}
.text-bold {
	font-weight: 600;
}
.text-bolder {
	font-weight: 700;
}
.text-black {
	color: $black !important;
}
.text-white {
	color: $white !important;
}
.text-orange {
	color: $color_4;
}
.img-responsive {
	max-width: 100%;
	height: auto;
}
.img-full {
	width: 100%;
	height: auto;
}
.center-block {
	margin: 0 auto;
}
.bg-white {
	background-color: $background-color_1;
}
.bg-off-white {
	background-color: $background-color_2;
}
.bg-cover {
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center;
}
.col-1 {
	width: 8.333333333333333%;
	float: left;
}
.col-2 {
	width: 16.66666666666667%;
	float: left;
}
.col-3 {
	width: 25%;
	float: left;
}
.col-4 {
	width: 33.33333333333333%;
	float: left;
}
.col-5 {
	width: 41.66666666666667%;
	float: left;
}
.col-6 {
	width: 50%;
	float: left;
}
.col-7 {
	width: 58.33333333333333%;
	float: left;
}
.col-8 {
	width: 66.66666666666667%;
	float: left;
}
.col-9 {
	width: 75%;
	float: left;
}
.col-10 {
	width: 83.33333333333333%;
	float: left;
}
.col-11 {
	width: 91.66666666666667%;
	float: left;
}
.col-12 {
	width: 100%;
	float: left;
}
.col-12-5 {
	width: 20%;
	float: left;
}
.w100 {
	width: 100%;
}
.w80 {
	width: 80%;
}
.w70 {
	width: 70%;
	float: left;
}
.w30 {
	width: 30%;
	float: left;
}
.w20 {
	width: 20%;
	float: left;
}
.cred {
	color: $color_5;
}
.h100 {
	height: 100%;
}
.mg-5 {
	margin: 5px;
}
.mg-10 {
	margin: 10px;
}
.mg-20 {
	margin: 20px;
}
.mg-30 {
	margin: 30px;
}
.mg-tp-5 {
	margin-top: 5px;
}
.mg-tp-10 {
	margin-top: 10px;
}
.mg-tp-20 {
	margin-top: 20px;
}
.mg-tp-30 {
	margin-top: 30px;
}
.mg-bt-5 {
	margin-bottom: 5px;
}
.mg-bt-10 {
	margin-bottom: 10px;
}
.mg-bt-20 {
	margin-bottom: 20px;
}
.mg-bt-30 {
	margin-bottom: 30px;
}
.mg-rt-5 {
	margin-right: 5px;
}
.mg-rt-10 {
	margin-right: 10px;
}
.mg-rt-20 {
	margin-right: 20px;
}
.mg-rt-30 {
	margin-right: 30px;
}
.mg-lt-5 {
	margin-left: 5px;
}
.mg-lt-10 {
	margin-left: 10px;
}
.mg-lt-20 {
	margin-left: 20px;
}
.mg-lt-30 {
	margin-left: 30px;
}
.pd-5 {
	padding: 5px;
}
.pd-10 {
	padding: 10px;
}
.pd-20 {
	padding: 20px;
}
.pd-30 {
	padding: 30px;
}
.pd-tp-5 {
	padding-top: 5px;
}
.pd-tp-10 {
	padding-top: 10px;
}
.pd-tp-20 {
	padding-top: 20px;
}
.pd-tp-30 {
	padding-top: 30px;
}
.pd-bt-5 {
	padding-bottom: 5px;
}
.pd-bt-10 {
	padding-bottom: 10px;
}
.pd-bt-20 {
	padding-bottom: 20px;
}
.pd-bt-30 {
	padding-bottom: 30px;
}
.pd-rt-5 {
	padding-right: 5px;
}
.pd-rt-10 {
	padding-right: 10px;
}
.pd-rt-20 {
	padding-right: 20px;
}
.pd-rt-30 {
	padding-right: 30px;
}
.pd-lt-5 {
	padding-left: 5px;
}
.pd-lt-10 {
	padding-left: 10px;
}
.pd-lt-20 {
	padding-left: 20px;
}
.pd-lt-30 {
	padding-left: 30px;
}
.h500 {
	height: 500px;
}
.h600 {
	height: 600px;
}
.h700 {
	height: 700px;
}
.h800 {
	height: 800px;
}
.pos-rel {
	position: relative;
}
.pos-abs {
	position: absolute;
}
.pos-fix {
	position: fixed;
}
.bottom {
	bottom: 0;
}
.top {
	top: 0;
}
.left {
	left: 0;
}
.right {
	right: 0;
}
.visible-md {
	display: none !important;
}
.visible-sm {
	display: none !important;
}
.visible-xs {
	display: none !important;
}
.hidden-xs {
	display: block;
}
.center-block{
	float: unset;
	margin: auto;
}
.row {
	&:before {
		display: table;
		content: " ";
	}
	&:after {
		display: table;
		content: " ";
		clear: both;
	}
}
.clearfix {
	clear: both;
}
.disptable {
	display: table;
	height: 100%;
}
.dispblock {
	display: block;
}
.table-cell {
	display: table-cell;
	vertical-align: middle;
}
.Yoverflowhidden {
	overflow-y: hidden;
}
.Xoverflowhidden {
	overflow-x: hidden;
}
.overflowhidden {
	overflow: hidden;
}
section {
	float: left;
	width: 100%;
}
section.padding {
	padding: 70px 0;
}
header {
	background-color: rgba(220, 223, 225, 1);
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	padding: 10px 0;
	z-index: 7;
	transition: all 0.6s ease-in-out;
	.upper {
		.number-wrapper {
			margin-right: 20px;
			margin-top: 10px;
		}
		.ctc-wrapper {
			margin-right: 20px;
		}
		.enq-btn-wrapper {
			a {
				display: inline-block;
				padding: 0px 15px;
				color: $color_3;
				background-color: $background-color_3;
				text-transform: uppercase;
				line-height: 40px;
			}
		}
	}
	.lower {
		.nav-links {
			text-align: center;
			transform: translateY(6px);
			a {
				font-family: $font-family_1;
				display: inline-block;
				margin-right: 20px;
				margin-top: 10px;
				color: $color_2;
				position: relative;
				text-transform: uppercase;
				font-weight: 400;
				font-size: 14px;
				&:last-child {
					margin-right: 0px;
				}
			}
			a.active {
				color: $color_6;
			}
		}
	}
	.header_enq_wrapper {
		transform: translateY(14px);
	}
	.form-container {
		position: absolute;
		width: 300px;
		top: calc(100% + 10px);
		right: 15px;
		background-color: $background-color_4;
		padding: 10px;
		display: none;
		.frmclose {
			display: none;
			display: inline-block;
			position: absolute;
			top: 10px;
			right: 10px;
			color: $color_2;
			font-size: 20px;
		}
		.thankyou {
			margin-bottom: 10px;
			margin-bottom: 10px;
		}
	}
}
// header.active {
// 	background-color: $background-color_1;
// 	z-index: 100000;
// }
.call-icon {
	svg {
		width: 20px;
		height: 20px;
		position: relative;
		right: 2px;
		top: 4px;
	}
}
.brand-logo{
	img{
		width: 100px
	}
}
footer{
	img{
		width: 180px
	}
}
.enquire-btn, .theme-btn {
	background-color: transparent;
	color: #000 !important;
	font-size: 14px;
	padding: 14px 20px;
	font-weight: 400;
	border: 1px solid #000;
	line-height: 16px;
	cursor: pointer;

	@media ($max1024) {
		padding: 10px;
	}
}
.eq-height-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
}
.home-banner {
	background-image: url('https://dummyimage.com/1366X700/6b6b6b/565659.png&text=banner');
}
.bar1 {
	transition: 0.4s;
}
.bar2 {
	transition: 0.4s;
}
.bar3 {
	transition: 0.4s;
}
.change {
	.bar1 {
		-webkit-transform: rotate(-45deg) translate(-11px, 4px);
		transform: rotate(-45deg) translate(-5px, 5px);
		width: 28px !important;
	}
	.bar2 {
		opacity: 0;
	}
	.bar3 {
		-webkit-transform: rotate(45deg) translate(-8px, -2px);
		transform: rotate(45deg) translate(-4px, -6px);
		width: 28px !important;
	}
}
footer {
	&.padding {
		padding: 70px 0;
		padding-bottom: 45px;
		background-color: #e0e1e1;
	}
	float: left;
	width: 100%;
	color: #333;
	a {
		color: #333;
	}
	.upper {
		background-color: $background-color_6;
		padding: 10px 0;
	}
	// .lower {
	// 	background-color: $background-color_7;
	// 	padding: 10px 0;
	// 	font-size: 12px;
	// }
}
/* ------------------------------ text animation code starts ---------------------------  */
.revealOnScroll {
	opacity: 0;
}
.animated {
	-moz-animation-duration: 1.2s;
	-o-animation-duration: 1.2s;
	-webkit-animation-duration: 1.2s;
	-ms-animation-duration: 1.2s;
	animation-duration: 1.2s;
	-moz-animation-fill-mode: both;
	-o-animation-fill-mode: both;
	-webkit-animation-fill-mode: both;
	-ms-animation-fill-mode: both;
	animation-fill-mode: both;
}

@keyframes fadeInDown {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -100%, 0);
		-o-transform: translate3d(0, -100%, 0);
		-moz-transform: translate3d(0, -100%, 0);
		-ms-transform: translate3d(0, -100%, 0);
		transform: translate3d(0, -100%, 0);
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		-o-transform: none;
		-moz-transform: none;
		-ms-transform: none;
		transform: none;
	}
}
.fadeInDown {
	-webkit-animation-name: fadeInDown;
	-o-animation-name: fadeInDown;
	-ms-animation-name: fadeInDown;
	-moz-animation-name: fadeInDown;
	animation-name: fadeInDown;
}

.fadeInUp {
	-webkit-animation-name: fadeInUp;
	-o-animation-name: fadeInUp;
	-ms-animation-name: fadeInUp;
	-moz-animation-name: fadeInUp;
	animation-name: fadeInUp;
}
@keyframes fadeInUp {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, 100%, 0);
		-o-transform: translate3d(0, 100%, 0);
		-ms-transform: translate3d(0, 100%, 0);
		-moz-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		-o-transform: none;
		-ms-transform: none;
		-moz-transform: none;
		transform: none;
	}
}

.fadeInLeft {
	-webkit-animation-name: fadeInLeft;
	-o-animation-name: fadeInLeft;
	-ms-animation-name: fadeInLeft;
	-moz-animation-name: fadeInLeft;
	animation-name: fadeInLeft;
}
@keyframes fadeInLeft {
	from {
		opacity: 0;
		-webkit-transform: translate3d(-100%, 0, 0);
		-o-transform: translate3d(-100%, 0, 0);
		-moz-transform: translate3d(-100%, 0, 0);
		-ms-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		-ms-transform: none;
		-moz-transform: none;
		-o-transform: none;
		transform: none;
	}
}
.fadeInRight {
	-webkit-animation-name: fadeInRight;
	-o-animation-name: fadeInRight;
	-ms-animation-name: fadeInRight;
	-moz-animation-name: fadeInRight;
	animation-name: fadeInRight;
}
@keyframes fadeInRight {
	from {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		-o-transform: translate3d(100%, 0, 0);
		-ms-transform: translate3d(100%, 0, 0);
		-moz-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}
	to {
		opacity: 1;
		-webkit-transform: none;
		-o-transform: none;
		-moz-transform: none;
		-ms-transform: none;
		transform: none;
	}
}


@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.fadeIn {
	-webkit-animation-name: fadeIn;
	-o-animation-name: fadeIn;
	-ms-animation-name: fadeIn;
	-moz-animation-name: fadeIn;
	animation-name: fadeIn;
}

/* ends here  */